.out-group-item {
    margin-bottom: 25px;
    border: 1px solid #0d6efd!important;
}

.out-group-item-invalid {
    margin-bottom: 25px;
    border: 1px solid red!important;
}

.in-group-item {
    padding-top: 20px;
}

.fly-title {
    cursor: pointer;
    font-weight: bold;
    width: fit-content;
    margin-top:-22px;
    background:white;
}

.fly-close {
    cursor: pointer;
    width: 18px;
    margin-top: -10px;
    margin-right: 5px;
    position:absolute;
    top:0;
    right:0;
}

.btn-add-component-product {
    font-size: .85rem;
    background-color: white;
    font-weight: bold;
    border: 0px solid $base;
    border-radius: 100px;
    color: black;
    padding: 5px;
    padding-right: 1rem;
  }