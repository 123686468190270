.choose-car-modal {
  .modal-content {
    height: fit-content;

    .modal-header {
      background-color: #fff;
      border-bottom: 1px solid #f6f6f4;
    }
  }
}

.confirm-modal {
  width: 400px;
  .modal-content {
    height: fit-content;
  }
  h4 {
    color: #1A1919;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
  span {
    color:  #676765;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 8px;
  }
  button {
    width: 47%;
  }
}

.modal-content {
  height: auto;

  .modal-header {
    background-color: #fff;
    border-bottom: 1px solid #f6f6f4;
  }
}

.datepicker-modal {
  button.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day {
    background: #fff;
  }
  button.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day:hover {
    color: #4E4B66 !important;
    background: #f9f9f9 !important;
  }
}

.edit-custom-modal {
  .card {
    border: none;

    .card-body {
      padding: 0;
    }
  }
}

// Mobile View
@media (max-width: 576px) {
  .confirm-modal {
    width: 100% !important;
  }
}
