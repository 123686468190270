// Base styles
.btn {
  display: inline-block;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight-md;
  line-height: $btn-line-height;
  color: $body-color;
  text-align: center;
  text-decoration: if($link-decoration == none, null, none);
  white-space: $btn-white-space;
  vertical-align: middle;
  cursor: if($enable-button-pointers, pointer, null);
  user-select: none;
  background-color: transparent;
  border: $btn-border-width-sm solid transparent;
  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius);
  @include transition($btn-transition);
  border-radius: $btn-border-radius;

  &:hover {
    color: $body-color;
    text-decoration: if($link-hover-decoration == underline, none, null);
  }

  .btn-check:focus + &,
  &:focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active,
  &.active {
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    pointer-events: none;
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }
}

// Alternate buttons
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant(
      $value,
      $value,
      $hover-background: map-get($all-colors, #{$color}-dark),
      $hover-border: map-get($all-colors, #{$color}-dark),
      $color: if($color == "light", $black-md, $white-xl),
      $hover-color: if($color == "light", $black-md, $white-md),
      $active-color: if($color == "light", $black-md, $white-md),
      $disabled-color: if($color == "light", $black-md, $black-xs)
    );
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant(
      if($color == "light", $black-sm, $value),
      $color-hover: map-get($theme-colors, $color),
      $active-background: map-get($all-colors, #{$color}-light),
    );
  }
}

// Link buttons
// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-normal;
  color: $btn-link-color;
  text-decoration: $link-decoration;

  &:hover {
    color: $btn-link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus {
    text-decoration: $link-hover-decoration;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
  }
}

// Subtle button variant based on Design System 1.1
.btn-subtle {
  background-color: $white-xl;
  color: $blue;
  border-color: $white-md;

  &:hover {
    color: $blue;
    background-color: $white-lg
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem $white-lg;
  }
}

// Button Sizes
.btn-lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $otoklix-font-size, $btn-border-radius-lg);
}

.btn-md {
  @include button-size($btn-padding-y-sm, $btn-padding-x-lg, $otoklix-font-size, $btn-border-radius);
}

.btn-sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-lg, $otoklix-font-size-xs, $btn-border-radius);
}

.btn-xs {
  @include button-size($btn-padding-y-xs, $btn-padding-x-xs, $otoklix-font-size-xs, $btn-border-radius);
}
