.breadcrumb {
  letter-spacing: 0.01em;
}

.breadcrumb-item {
  a {
    color: $secondary;
  }

  +.breadcrumb-item {
    padding-left: $breadcrumb-item-padding-x;

    &::before {
      font-weight: 400;
    }
  }

  &.active {
    color: $breadcrumb-active-color;
    font-weight: 700;
  }
}
