hr {
  border: none;
  height: 1px;
  color: $black-xs;
  background-color: $black-xs;
  margin: $divider-margin-default auto;
}

.divider-text {
  padding-top: 1rem;
  padding-bottom: 1rem;

  h1 {
    position: relative;
    font-size: $otoklix-font-size-sm;
    z-index: 1;
    overflow: hidden;
    text-align: center;
    color: $label;
  }
  
  h1:before, h1:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 50%;
    height: 1px;
    content: '\a0';
    background-color: $line;
  }
  
  h1:before {
    margin-left: -50%;
    text-align: right;
  }
}

.dash {
  border: 0 none;
  border-top: 2px dashed $black-sm;
  background: none;
  height:0;
}
