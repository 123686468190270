//
// Text truncation
//

.text-truncate {
  @include text-truncate();
}

.text-truncate-double {
  @include text-truncate-double();
}
