.box-alert {
  display: flex;
  align-items: center;
  padding: $alert-padding-y $alert-padding-x;
  border-radius: $border-radius;
  border-width: 1px;
  border-style: solid;

  .image {
    margin-right: 0.5rem;
  }

  .text {
    flex-grow: 1;
    font-size: $otoklix-font-size-xs;
    line-height: $otoklix-line-height-sm;
    font-weight: 500;
  }

  a {
    font-weight: 700;
    cursor: pointer;
    text-decoration: underline !important;
  }

  .close {
    cursor: pointer;
    padding-left: 0.5rem;
  }

  &.--floating {
    position: fixed;
    bottom: 2.5rem;
    width: 85%;
    left: 0;
    right: 0;
    margin: auto
  }
}
