@function px2rem($px){
  @if unitless($px) {
    @return 1rem*($px/16);
  } @else {
    @return 1rem*($px/16px);
  }
}

@function rem2px($rem){
  @if unitless($rem) {
    @return 16px*($rem);
  } @else {
    @return 16px*($rem/1rem);
  }
}

$blue:                  #002EB4 !default;
$blue-dark:             #0F2E82 !default;
$blue-light:            #D3E4FF !default;

$orange:                #FF6400 !default;
$orange-dark:           #C34600 !default;
$orange-light:          #FFE7D5 !default;

$red:                   #D91F11 !default;
$red-dark:              #A1160A !default;
$red-light:             #FFF2F7 !default;

$green:                 #00BA88 !default;
$green-dark:            #00966D !default;
$green-light:           #DBFBF2 !default;

$yellow:                #EB9C03 !default;
$yellow-dark:           #A26B00 !default;
$yellow-light:          #FFF0D4 !default;

$white-xl:              #FCFCFC !default;
$white-lg:              #F7F7FC !default;
$white-md:              #EFF0F7 !default;

$black-xl:              #14142B !default;
$black-lg:              #4E4B66 !default;
$black-md:              #6E7191 !default;
$black-sm:              #A0A3BD !default;
$black-xs:              #D6D8E7 !default;

$cyan:                  #0dcaf0 !default;

$primary:                 $blue !default;
$secondary:               $orange !default;
$success:                 $green !default;
$info:                    $cyan !default;
$warning:                 $yellow !default;
$danger:                  $red !default;
$light:                   $black-xs !default;
$dark:                    $black-lg !default;

$input-bg:                $white-md !default;
$line:                    $black-xs !default;
$title-active:            $black-xl !default;
$placeholder:             $black-sm !default;
$offwhite:                $white-xl !default;
$label:                   $black-md !default;

$theme-colors: (
  "primary":         $primary,
  "primary-light":   $blue-light,
  "secondary":       $secondary,
  "success":         $success,
  "success-light":   $green-light,
  "info":            $info,
  "warning":         $warning,
  "warning-light":   $yellow-light,
  "danger":          $danger,
  "danger-light":    $red-light,
  "light":           $light,
  "dark":            $dark,
  "off-white":       $offwhite,
  "line":            $line,
  "secondary-light": $orange-light,
  "bg":              $white-lg,
  "white-lg":        $white-lg,
  "white-md":        $white-md,
) !default;

$all-colors: (
  "primary-dark":    $blue-dark,
  "primary-light":   $blue-light,
  "secondary-dark":  $orange-dark,
  "secondary-light": $orange-light,
  "success-dark":    $green-dark,
  "success-light":   $green-light,
  "info-dark":       $cyan,
  "info-light":      $cyan,
  "warning-dark":    $yellow-dark,
  "warning-light":   $yellow-light,
  "error-dark":      $red-dark,
  "error-light":     $red-light,
  "danger-dark":     $red-dark,
  "danger-light":    $red-light,
  "light-dark":      $black-sm,
  "light-light":     $black-md,
  "title-active":    $title-active,
  "body":            $black-lg,
  "label":           $label,
  "placeholder":     $placeholder,
  "line":            $line,
  "input-bg":        $input-bg,
  "bg":              $white-lg,
  "off-white":       $offwhite
) !default;

$wrapper-breakpoints: (
  xs: 360px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;

// appbar
$appbar-height:                   56px;

// otoklix-font-weight
$font-weight-600: 600;
$font-weight-700: 700;

// Disable shadow
$enable-shadows:                  false;
$otoklix-font-size-xxxs:          px2rem(8) !default;
$otoklix-font-size-xxs:           px2rem(10) !default;
$otoklix-font-size-xs:            px2rem(12) !default;
$otoklix-font-size-sm:            px2rem(13) !default;
$otoklix-font-size-md:            px2rem(14) !default;
$otoklix-font-size:               px2rem(16) !default;
$otoklix-font-size-lg:            px2rem(24) !default;

// Font line height
$otoklix-line-height-xs:          px2rem(16) !default;
$otoklix-line-height-sm:          px2rem(22) !default;

// border radius
$border-radius-xs:                px2rem(4) !default;
$border-radius-sm:                px2rem(8) !default;
$border-radius:                   px2rem(24) !default;
$border-radius-md:                px2rem(14) !default;
$border-radius-lg:                px2rem(16) !default;
$border-radius-xl:                px2rem(20) !default;

// button variables
$btn-font-weight-md:              600;

$btn-border-radius:               $border-radius;
$btn-border-radius-lg:            $border-radius-lg;

$btn-font-size-lg:                px2rem(18) !default;
$btn-font-size-sm:                $otoklix-font-size-sm;
$btn-padding-y-sm:                px2rem(10) !default;
$btn-padding-y-lg:                px2rem(14) !default;
$btn-padding-x-lg:                px2rem(20) !default;

$btn-padding-y-xs:                px2rem(8)  !default;
$btn-padding-x-xs:                px2rem(4) !default;

$btn-border-width-sm:             2px;

// input variables
$input-padding-y:                 px2rem(18) !default;
$input-padding-y-sm:              px2rem(14) !default;
$input-padding-y-lg:              px2rem(20) !default;
$input-padding-x:                 px2rem(24) !default;
$input-padding-x-sm:              px2rem(20) !default;
$input-padding-x-lg:              px2rem(26) !default;
$input-padding-x-xl:              px2rem(40) !default;
$input-padding-x-xxl:             px2rem(48) !default;

$input-placeholder-color:         $placeholder;
$input-color:                     $title-active;
$input-border-width:              1px;
$input-disabled-bg:               $white-lg;

$label-floating-padding-x:        px2rem(26) !default;
$label-floating-padding-x-lg:     px2rem(60) !default;
$label-floating-padding-x-xxl:    px2rem(53) !default;

$label-floating-padding-y:        px2rem(19) !default;
$form-floating-height:            px2rem(64) !default;

// icon variables
$container-icon:                  px2rem(56) !default;
$container-icon-md:               px2rem(48) !default;
$container-icon-sm:               px2rem(40) !default;
$container-icon-xs:               px2rem(24) !default;
$container-icon-xxs:              px2rem(20) !default;
$spacing-icon:                    px2rem(5)  !default;
$icon-text-spacing:               px2rem(8)  !default;

// badge variables
$badge-top:                       px2rem(-5) !default;
$badge-right:                     px2rem(-5) !default;
$badge-top-card:                  px2rem(8) !default;
$badge-right-card:                px2rem(8) !default;
$badge-left-card:                 px2rem(8) !default;
$badge-padding-y:                 px2rem(4) !default;
$badge-padding-x:                 px2rem(8) !default;

// alert variables
$alert-padding-y:                 px2rem(8)  !default;
$alert-padding-x:                 px2rem(12) !default;

// card variables
$card-image-default-width:        px2rem(180) !default;
$card-ototips-image-default-width:px2rem(80) !default;
$card-ototips-default-width:      px2rem(360) !default;
$card-padding-lg:                 px2rem(20)  !default;
$card-box-shadow:                 0 3px 6px rgba(0,0,0,0.16), 0 2px 4px rgba(0,0,0,0.12) !default;
$card-box-shadow-sm:              4px 4px 16px rgba(0,0,0, 0.08) !default;
$card-box-shadow-md:              0px 32px 40px rgba(17, 17, 17, 0.08) !default;

// divider variables
$divider-margin-default:          px2rem(12) !default;
$divider-margin-lg:               px2rem(16) !default;

// carousel variables
$carousel-height:                 px2rem(500) !default;
$carousel-default-image-height:   px2rem(256) !default;
$carousel-caption-top:            px2rem(288) !default;
$carousel-indicator-height:       px2rem(4) !default;
$carousel-indicator-width:        px2rem(4) !default;

// breadcrumb variables

$breadcrumb-font-size:            px2rem(12) !default;
$breadcrumb-item-padding-x:       px2rem(4) !default;
$breadcrumb-divider:              quote(">") !default;
$breadcrumb-active-color:         $label !default;
$breadcrumb-divider-color:        $placeholder !default;
