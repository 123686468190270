.orders {
  .head {
    div:nth-of-type(1) {
      display: flex;
      align-items: center;

      span {
        font-weight: 700;
        font-size: px2rem(20);
        color: #1A1919;
      }
    }
  }

  button#create-order {
    padding: 10px 20px;
  }
}

.new-order {
  &__body {
    display: flex;
    flex-wrap: wrap;
  }

  .extras {
    .btn-save-draft {
      border-radius: 24px;
      padding: 10px 20px;
      background: #E6E6E4;
      margin-right: 8px;
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        width: 20px;
        height: 20px;
      }

      span {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        color: #454443;
      }
    }

    .btn-cancel-order {
      border-radius: 24px;
      padding: 10px;
      background: #E6E6E4;
      cursor: pointer;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__body {
    .product-head {
      .stepper-wrapper {
        height: 132px;
        background-color: #fff;
        display: flex;
        align-items: center;
        width: 100%;
      }

      .product-tabs-head {
        width: 100%;
        background-color: #fff;
        border-bottom: 1px solid #E6E6E4;
        padding: 8px;
        

        &__item {
          padding: 8px 16px;
          cursor: pointer;
        }

        &__item.active {
          border-bottom: 2px solid #002EB4
        }
      }
    }
  }
}

.order-summary {
  border-left: 1px solid #e6e6e4;
  background-color: #fff;
  min-height: calc(100vh - 72px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .item-wrapper {
    width: 100%;
    padding-top: 16px;

    .items {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;

      .qty {
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0px;
        color: #002EB4;
        margin-right: 8px;
        width: 29px;
      }

      .name {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;
        color: #454443;
      }

      .price {
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0px;
        color: #1A1919;
      }
    }
  }
}

.scroller-wrapper {
  overflow-y: auto;
  height: 78vh;
  padding-bottom: 20px;
}

.cards {
  display: grid;
  grid-template-columns: repeat(6, minmax(158px, 1fr));
  grid-template-rows: auto;
  gap: 20px;

  // display: flex;
  // flex-wrap: wrap;
  // grid-template-columns: repeat(auto-fit, minmax(576px, 1fr));
  // gap: 20px;
}

.wrapper-fixed-bottom.orders {
  z-index: 1;
}

.card-product.is-empty {
  background-color: #E6E6E4;

  .img-wrapper {  
    .img-placeholder {
      background-color: #d4d4d0;
    }
  }

  .item-name {
    color: #454443;
  }
}

.card-product {
  background-color: #fff;
  display: grid;
  width: 168px;
  height: 228px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #E6E6E4;
  margin: 0 auto;

  .img-wrapper {
    position: relative;

    .item-badge {
      position: absolute;
      top: 16px;
      right: 4px;
      z-index: 1;
    }

    .item-badge-oil {
      position: absolute;
      bottom: 10px;
      right: 4px;
      color: #454443;
      border-radius: 4px !important;
    }

    .compatible {
      border-radius: 8px 8px 8px 0px;
      padding: 2px 6px 2px 6px;
      background-color: #077D55;
      color: #fff;
      width: fit-content;
      font-size: 9px;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: 0px;
      position: absolute;
      top: -4px;
      left: -12px;
      z-index: 1;
    }

    .compatible::before {
      content: "";
      position: absolute;
      height: 0;
      width: 0;
      bottom: -6px;
      left: 0;
      border-top: 6px solid #077D55 !important;
      border-left: 4px solid transparent;
    }

    .item-empty {
      position: absolute;
      width: 64px;
      height: 64px;
      top: 45px;
      left: 45px;
      padding: 4px;
      border-radius: 32px;
      color: #fff;
      background-color: #1A1919;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
    }
  }

  .img-display {
    display: block;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .not-sellable {
    filter: grayscale(95%);
  }

  .img-placeholder {
    width: 150px;
    height: 154px;
    border-radius: 4px;
    background-color: #e6e6e4;
  }
}

.detail-card-product {
  width: 100%;
  display: block;
  padding: 12px;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--neutral-20, #E6E6E4);
  position: relative;

  .img-display {
    border-radius: 6px;
    object-position: center;
    object-fit: cover;
  }

  .title-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .item-wrapper {
    width: 100%;
  }

  .content-wrapper {
    width: 100%;
    margin-left: 6px;
  }

  .promo-wrapper {
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: #002eb5;
    left: 0;
    border-radius: 0 0 8px 8px;
    color: #fcfcfc;
    display: flex;
    justify-content: space-between;
    padding: 0.4rem 1rem;
  }
}

.price-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
}

.package-price {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  color: #002EB4;
}

.time-button {
  padding: 4px 6px;
  // border: 1px solid #002EB4;
  // border-radius: 8px;
}

.time-button.disabled {
  padding: 4px 6px;
  color: #a1a2b0;
}

.box-upload {
  text-align: center;
  background: #FFFFFF;
  border: 1px dashed #ADB5BD;
  position: relative;
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
}

.box-image-preview {
  background: #FFFFFF;
  border: 1px dashed #ADB5BD;
  position: relative;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  img {
    border-radius: 4px;
    cursor: pointer;
  }
  .title-image {
    margin-left: 12px;
    font-size: 14px;
    color: #1A1919;
  }
  .icon-trash {
    object-fit: unset !important;
    position: absolute !important;
    cursor: pointer;
    width: 21px !important;
    height: 21px !important;
    right: 10px;
    top: 24px;
  }
}

.margin-selector {
  .col {
    padding: 0;
  }
}

@media screen and (min-width: 2560px) {
  .cards {
    gap: 36px;
    grid-template-columns: repeat(8, minmax(158px, 1fr));
  }
}

@media screen and (min-width: 1442px) {}

@media screen and (max-width: 1680px) {
  .cards {
    gap: 30px;
  }
}

@media screen and (max-width: 1280px) {
  .cards {
    gap: 36px;
    grid-template-columns: repeat(4, minmax(158px, 1fr));
  }
}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 767px) {
  .cards {
    gap: 16px 0;
    grid-template-columns: repeat(4, minmax(158px, 1fr));
  }

  .new-order__body .product-head .stepper-wrapper {
    height: 86px;
  }
  
  .scroller-wrapper {
    height: 46vh;
  }
}

@media screen and (max-width: 576px) {
  .product-tabs-head {
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #E6E6E4;
    padding: 8px;
    font-size: 0.65rem;
    &__item {
      padding: 8px 16px;
      cursor: pointer;
    }
    &__item.active {
      border-bottom: 2px solid #002EB4
    }
  }
  .cards {
    gap: 10px 0px;
    grid-template-columns: repeat(3, minmax(158px, 1fr));
  }
}

@media screen and (max-width: 414px) {
  .cards {
    gap: 10px 0px;
    grid-template-columns: repeat(2, minmax(158px, 1fr));
  }
}

@media screen and (max-width: 360px) {
  .cards {
    gap: 10px 0px;
    grid-template-columns: repeat(1, minmax(158px, 1fr));
  }
}
