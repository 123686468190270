/* Override Metrica */

dt {
  line-height: 17px;
  font-weight: 700;
  color: #6c757d;
}

dd {
  color: #6c757d;

  &.label {
    color: #ced4da;
  }
}

.page-title-box .page-title {
  color: $black-lg !important;
}

.card-header {
  padding: 9px 16px !important;
  background-color: var(--primary) !important;
  font-size: 1rem;
  border-radius: 4px 4px 0 0;
}

.card {
  border-radius: px2rem(8);
}

/* Override Metrica */

.page-wrapper {
  .page-content {
    min-height: auto;
  }
}

.page-content-tab {
  background-color: #e9ecef;
  padding: 0 10px 10px 10px;
  min-height: 100vh;
  margin-top: 0px;
}

.bottom-radius {
  border-radius: 0 0 3px 3px;
}

.list-item-services {
  &:hover {
    background-color: $light;
  }
}

textarea.form-control {
  min-height: 150px;
}

.left-sidenav-menu li > a.active {
  color: $primary;
}

.custom-checkbox-circle {
  padding-left: 19px;
}

.custom-checkbox-circle input[type='checkbox']:disabled + label {
  opacity: 1;
}

.user-car {
  position: absolute;
  // opacity: 0.3;
  top: 0;
  right: 0;
  width: 150px;
  margin-top: 20px;
}

.user-car-detail {
  position: absolute;
  opacity: 0.3;
  bottom: 10px;
  right: 10px;
  width: 350px;
}

.topbar,
.leftbar-profile {
  border-bottom: 1px solid $border;
}

.left-sidenav-menu {
  padding: 0 5px 5px 5px;
}

.left-sidenav-menu li {
  margin-top: 5px;
}

.left-sidenav-menu li > a {
  padding: 10px;
  position: relative;

  span {
    position: absolute;
    top: 12px;
  }
}

.auth-bg {
  background-image: url('../assets/images/auth-bg.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(black, 0.04);
}

.login-bg {
  background-image: url('../assets/images/login-bg.jpeg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(black, 0.04);
}

.change-pwd-bg {
  background-image: url('../assets/images/forgot-password-bg.jpeg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(black, 0.04);
}

.btn-login {
  color: #ffffff;
  background-color: #060606;
  border: 1px solid #060606;
  // border-bottom: 20px;
  margin: 0px 5px 0px 0px;
  &:hover{
    color: #ffffff;
      background-color: rgba(black, 0.8);
      border: 1px solid rgba(black, 0.8);
  }
  &::after {
    color: #ffffff;
    background-color: rgba(black, 0.8);
    border: 1px solid rgba(black, 0.8);
  }
}

.input-login {
  border: none !important; 
  border-bottom: 1px solid #E0E0E0 !important;
  &::-webkit-input-placeholder {
    color: black !important;
  }
  &::placeholder {
    color: black !important;
  }
  &:-webkit-input-placeholder {
    color: black !important;
  }
}

.mask-input {
  // border: 1px solid #CED4DA !important;
  &::-webkit-input-placeholder {
    color: #cecbcb !important;
  }
  &::placeholder {
    color: #cecbcb !important;
  }
  &:-webkit-input-placeholder {
    color: #cecbcb !important;
  }
  &:disabled {
    background-color: #DEE2E6;
  }
}

.mask-input .valid {
  border: 1px solid #CED4DA !important;
}

.mask-input .invalid {
  border: 1px solid red !important;
}

.text-red {
  color: red;
}

.text-black {
  color: #060606;
}

.text-grey {
  color: #6b7b9a;
}

.text-purple-2 {
  color: rgba(102, 16, 242, 1);
}

.br-0 {
  border-radius: 0 !important;
}

.nav-transparent {
  background-color: transparent !important;
  cursor: pointer;
}

.p-big {
  padding-top: 200px;
  padding-bottom: 200px;
}

.rdt_TableHeader {
  border: 1px solid $border;
  border-bottom-width: 0;
  background-color: white !important;
  border-radius: 5px 5px 0 0;
}

.rdt_TableRow {
  cursor: pointer;
}

.wrapperContent {
  header {
    border: 1px solid $border;
    border-top: 1px solid $sub_border;
    background-color: white !important;
    padding-right: 8px;
  }
}

.form-control {
  height: 35px;
}

.no-zindex {
  z-index: 0;
}

.fixed-fc-height {
  border: 1px solid $info;
}

.width-250 {
  width: 250px !important;
}

.marker {
  position: absolute;
  width: 38px;
  height: 37px;
  background-image: url(https://icon-library.com/images/pin-icon-png/pin-icon-png-9.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: grab;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: rgba(241, 241, 241, 1);
}

::-webkit-scrollbar:hover {
  background-color: rgba(193, 193, 193, 0.09);
}

::-webkit-scrollbar-thumb {
  background: rgba(193, 193, 193, 0.5);
}

::-webkit-scrollbar-thumb:active {
  background: rgba(193, 193, 193, 0.61);
}

::-webkit-scrollbar-thumb:vertical {
  min-height: 10px;
}

::-webkit-scrollbar-thumb:horizontal {
  min-width: 10px;
}

tr.package-details td {
  border-bottom: 1px solid transparent !important;
  padding: 5px 15px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: '>';
}

.nav-tabs {
  .nav-link {
    cursor: pointer;
    border-color: #f1f5fa #f1f5fa #eceff5;

    &.active {
      background-color: $base;
      color: $primary;
      border-color: $primary;
      border-bottom: none;
    }
  }
}

.page-title-box {
  padding: 10px 0 25px;

  .breadcrumb {
    margin-bottom: 15px;

    &-item {
      a {
        color: $primary;
      }

      &.active {
        a {
          color: $default;
        }
      }
    }
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  content: '>';
}

.nav-tabs {
  .nav-link {
    cursor: pointer;
    border-color: #f1f5fa #f1f5fa #eceff5;

    &.active {
      background-color: $base;
      color: $primary;
      border-color: $primary;
      border-bottom: none;
    }
  }
}

.input-group-prepend {
  .btn {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.box.nothing {
  img {
    width: 200px !important;
    height: 200px !important;
    object-fit: cover;
    border: 3px solid $primary;
  }
}

.galeri-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-column-gap: 20px;
  width: 100%;
  padding: 5px;

  .box {
    border-radius: 5px;
    overflow: hidden;
    margin-top: 0;
    margin-bottom: 20px;
    position: relative;

    img {
      object-fit: cover;
      width: 204px;    
      height: 114px;
      border-radius: 10px;
    }
    
    .container-cx-round {
      position: absolute;
      left: 5px;
      top: 5px;

      .round {
        position: relative;
      }

      .round input[type="checkbox"] {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.box.nothing-video {
  img {
    width: 200px !important;
    height: 150px !important;
    object-fit: cover;
    border: 3px solid $primary;
  }

  h1 {
    font-size: 13px;
    font-weight: 700;
    margin: 0;
    line-height: 25px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  h2 {
    font-size: 13px;
    font-weight: 400;
    margin: 0;
    line-height: 20px;     
    overflow: hidden;
  }
}

.video-container {
  .box {
    img {
      height: 150px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    h1 {
      font-size: 13px;
      font-weight: 700;
      margin: 0;
      line-height: 25px;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    h2 {
      font-size: 13px;
      font-weight: 400;
      margin: 0;
      line-height: 20px;     
    }
  }
}

.btn-add-foto {
  background-color: $base;
  font-weight: 700;
  font-size: 1rem;
  color: $primary;
  border: 1px solid $base;
  border-radius: 100px;
  padding: 5px;
  padding-right: 1rem;
}

.notif-list{
  background: #FFFFFF!important;
}

.notif-list:hover{
  background: #e0e0e0!important;
}

.notif-loader{
  margin: 10px;
  color: #0d6efd!important;;
}

body {
  overflow: --moz-scrollbars-vertical;
  overflow: scroll; /* Show scrollbars */
}
