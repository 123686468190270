.widget {
  border-radius: $border-radius;
  
  .title {
    @include font-size($otoklix-font-size-sm);
    font-weight: 500;
    color: $black-xl;
  }
  
  .subtitle {
    @include font-size($otoklix-font-size-sm);
  }
  
  .vertical-center-box {
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
}

.widget-item {
  .title {
    color: $title-active;
    font-weight: 600;
    font-size: $otoklix-font-size-xs;
  }
}
