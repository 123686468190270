.carousel-indicators {
  cursor: pointer;
  
  li {
    width: $carousel-indicator-width;
    height: $carousel-indicator-height;
    border-radius: $border-radius-xs;
    background-color: $black-xs;
  }
  
  li.active {
    background-color: $orange;
    width: 1rem;
  }
}

.carousel-caption {
  color: $black-lg;

  .carousel-caption-header {
    font-size: $otoklix-font-size-lg;
  }
  
  .carousel-caption-text {
    font-size: $otoklix-font-size-md;
  }
}

.onBoard {
  .carousel-indicators {
    top: $carousel-caption-top;
    bottom: unset;
  }

  .carousel-inner {
    height: $carousel-height;

    .carousel-item {
      img {
        height: $carousel-default-image-height;
      }

      .carousel-caption {
        bottom: 0;
        top: $carousel-caption-top;
        right: 0;
        left: 0;
      }
    }
  }
}
