.menu-resp {
  display: none;
}

.mdi-menu, .mdi-close {
  font-size: 32px;
}

.gmap-autocomplete-wrapper {
  position: absolute;
  width: calc(100% - 19px);
  left: 10px;
  right: 10px;
  top: 0px;

  .disabled-input {
    width: 100%;
    height: 38px;
    background-color: #000;
    opacity: 10%;
    position: absolute;
    top: 0;
  }
}

.navbar-custom {
  display: none;
}

.wrapper-no-frame {
  .wrapper-head {
    min-height: px2rem(72);
    border-bottom: 1px solid #e6e6e4;

    img {
      width: px2rem(114);
    }

    span {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
    }
  }

  .wrapper-body {
    min-height: calc(100vh - 72px);
    background-color: #f5f5f7;
  }
}

@media screen and (min-width: 1442px) {
  .vertical-line {
    border-left: 0.09rem solid #b7bbc2;
    height: 200px;
    object-fit: unset!important;
    position: absolute!important;
    width: unset!important;
    right: 0;
  }
  .filter-sides {
    justify-content: end;
    display: flex;
  }
  .btn-approve-resp {
    margin: 2px 0 0 5px;
    align-self: flex-start;
  }
  .btn-wide {
    width: 100px;
  }
  .btn-filter-width {
    width: 155px;
  }
}

@media screen and (max-width: 1442px) {
  .vertical-line {
    border-left: 0.09rem solid #b7bbc2;
    height: 200px;
    object-fit: unset!important;
    position: absolute!important;
    width: unset!important;
    right: 0;
  }
  .filter-sides {
    justify-content: end;
    display: flex;
  }
  .btn-approve-resp {
    margin: 2px 0 0 5px;
    align-self: flex-start;
  }
  .btn-wide {
    width: 100px;
  }
  .btn-filter-width {
    width: 115px;
    margin: 2 !important;
  }
}

@media screen and (max-width: 1024px) {
  .topbar {
    .navbar-custom {
      display: block;
      margin-left: 0;
    }
  }

  .left-sidenav {
    position: fixed;
    border-right: 1px solid #d9d9d9;
    box-shadow: 2px 0 3px 0 #d9d9d9;
    -webkit-transition: width 0.5s ease-in-out;
    -moz-transition: width 0.5s ease-in-out;
    -o-transition: width 0.5s ease-in-out;
    transition: width 0.5s ease-in-out;

    .left-sidenav-menu {
      height: auto !important;
    }
  }

  .menu-resp {
    display: block;
  }

  .w-0 {
    width: 0px !important;
    -webkit-transition: width 0.5s ease-in-out;
    -moz-transition: width 0.5s ease-in-out;
    -o-transition: width 0.5s ease-in-out;
    transition: width 0.5s ease-in-out;
  }

  .filter-sides {
    justify-content: end;
    display: flex;
  }

  .page-content-tab {
    min-height: 95vh;
    margin-top: 60px;
  }
}

@media screen and (max-width: 767px) {
  .logo-rv {
    display: none;
  }
  .breadcrumb {
    display: flex!important;
  }
  .tab-item{
    width: 100%;
  }
  .section-pagination{
    .section-row-per-page{
        width: 100%!important;
        justify-content: center;
    }
    .section-info-paging{
        width: 100%!important;
        text-align: center!important;
    }
    .section-paging{
        width: 100%!important;
        text-align: center!important;
        margin-top: 10px;
    }
  }
  .vertical-line {
    border-right: 0px transparent white;
    height: 0;
  }
  .filter-sides {
    width: 100%;
    display: block;
  }
  .btn-approve-resp {
    margin: 10px 0 0 0;
  }
}

@media screen and (min-width : 577px) {
  .modal-dialog-centered {
    &.modal-payment{
      .modal-content {
        min-width: 600px !important;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .col-form-label {
    text-align: left;
  }

  .user-car-detail {
    width: 300px;
  }

  .wrapperContent {
    header {
      padding: 5px;

      .form-inline {
        width: 100%;

        .form-group {
          width: 100%;
        }

        .width-250 {
          width: 100% !important;
        }
      }
    }
  }

  .section-action {
    .button-action{
      width: 100%!important;
    }
  }
  .section-filters{
    .text-field-filter{
      width: 100%!important;
    }
    .select-field-filter{
      width: 100%!important;
    }
    .text-field-range-price-filter{
      width: 100%!important;
      .content-range-price{
        width: 100%!important;
        .wrapper-range-price{
          flex-direction: column!important;
        }
      }
    }
    .select-text-field-filter{
      width: 100%!important;
    }
    .react-datepicker-wrapper{
      width: 100%!important;
    }
    .button-reset-filter{
      width: 100%!important;
    }
  }
  .btn-create-responsive {
    width: 100%;
  }
}

.menu-toggle {
  position: relative;
  display: block;
  width: 25px;
  height: 20px;
  background: transparent;
  border-top: 3px solid;
  border-bottom: 3px solid;
  color: #676765;
  font-size: 0;
  transition: all .3s ease-in-out;

  &:before,
  &:after {
    content: '';
    display: flex;
    width: 100%;
    height: 3px;
    color: #676765;
    position: absolute;
    top: 50%;
    left: 50%;
    background: #676765;
    transform: translate(-50%, -50%);
    transition: transform .3s ease-in-out;
  }

  &.active {
    border-color: transparent;
  
    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  
    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:before,
    &:after {
      width: 100%;
    }
  }

  .modal-dialog-centered {
    &.modal-payment{
      .modal-content {
        min-width: 100vw;
      }
    }
  }
}
