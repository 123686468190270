.card-trending {
  background: $white-xl;
  border-radius: $border-radius-xl;

  .card-body {
    height: 120px;
  }

  img.card-image {
    border-radius: $border-radius-xl;
  }

  .card-title {
    font-size: $otoklix-font-size-sm;
  }

  .card-subtitle {
    position: relative !important;
    right: unset !important;
    font-size: $otoklix-font-size-xxs !important;
  }

  .card-text {
    font-size: $otoklix-font-size-xxs;
  }

  .badge-top-right {
    position: absolute;
    right: $badge-right-card;
    top: $badge-top-card;
    z-index: 1;

    .icon-text {
      color: $secondary;
    }
  }

  .icon-text {
    font-size: $otoklix-font-size-xxs;
  }
}

.card-ototips {
  .left-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
  }

  .badge-title {
    color: $secondary;
    font-weight: 600;
    font-size: $otoklix-font-size-xs;
  }

  .right-badge-wrapper {
    .right-badge {
      font-size: $otoklix-font-size-sm;
    }
  }

  .icon-text {
    font-size: $otoklix-font-size-xxs;
  }

  .subtitle {
    font-size: $otoklix-font-size-sm;
    color: $black-xl;
    font-weight: 500;
  }
}

.card-garage {
  .edit-pen {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .card-title {
    font-weight: 600;
    color: $title-active;
    font-size: 1rem;
    align-items: center;
    display: flex;
  }

  .card-body {
    min-height: 310px;
  }

  .card-subtitle {
    font-weight: 500;
    font-size: $otoklix-font-size-sm;
    color: $label;
  }

  .card-info {
    background: $input-bg;
    border-radius: 0 0 $border-radius $border-radius;
    border-top: 1px solid #e6e6e6;
  }

  .card-detail {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .arrow-collapse {
    cursor: pointer;
    position: absolute;
    bottom: px2rem(5);
    right: px2rem(12);
  }

  .title {
    color: $title-active;
    font-size: $otoklix-font-size-sm;
    font-weight: 600;
  }

  .subtitle {
    font-weight: 600;
    font-size: $otoklix-font-size-sm;
  }
}

.card-workshops {
  box-shadow: $card-box-shadow-md;
  border: 0px;
  border-radius: 20px;

  .card-image {
    object-fit: cover;
  }

  .title {
    font-size: $otoklix-font-size-md;
    line-height: px2rem(21);
    max-height: px2rem(21); // 1 * 21
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  .subtitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    font-size: $otoklix-font-size-xxs;
    color: $label;
  }

  .dot {
    background-color: $label;
    width: 4px;
    height: 4px;
    border-radius: 50%;
  }

  .badge-top-right {
    position: absolute;
    right: $badge-right-card;
    top: $badge-top-card;
    z-index: 1;
  }

  .pricing-section {
    flex-grow: 1;
    overflow-wrap: anywhere;
  }

  .cta-container {
    display: flex;
    align-items: flex-start;
    white-space: nowrap;
  }
}

.card-workshops-slide {
  border: 0px;
  box-shadow: $card-box-shadow-md;
  border-radius: $border-radius-lg;
   
  .card-body{
    display: flex;
    padding: px2rem(8);
  }

  .card-image {
    object-fit: cover;
    margin-right: px2rem(8);
    border-radius: $border-radius-sm;
  }

  .title {
    overflow: hidden;
    color: $black-lg;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    letter-spacing: 0.75px;
    line-height: px2rem(21);
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    font-weight: $font-weight-bold;
    font-size: $otoklix-font-size-md;
  }

  .sub-title {
    color: $black-sm;
    letter-spacing: 0.75px;
    line-height: px2rem(15);
    font-weight: $font-weight-normal;
    font-size: $otoklix-font-size-xxs;
  }

  .pricing-section {
    height: 18px;
    line-height: 0px;
    flex-direction: row;
    align-items: center;
  }

  .container-bottom{
    margin-top: 8px;
    white-space: nowrap;
    align-items: flex-start;
  }

  .box-icon {
    width: $container-icon-sm;
  
    .icon {
      width: unset;
      height: unset;
      min-height: unset;
    }
  }
  
  .text-rating{
    padding-top: 3px;
    color: $black-lg;
    min-height: 1.25rem;
    font-weight: $font-weight-normal;
    font-size: $otoklix-font-size-xxs;
  }

  .icon-rating{
    margin-right: 5px;
  }

  .btn-show-workshop{
    right: 8px;
    width: 98px;
    height: 24px;
    display: flex;
    color: $white-xl;
    position: absolute;
    border-radius: 6px;
    align-items: center;
    flex-direction: row;
    letter-spacing: 0.75px;
    line-height: px2rem(15);
    justify-content: center;
    font-weight: $font-weight-bold;
    font-size: $otoklix-font-size-xxs;
  }
}

.card-package {
  border: none !important;
  box-shadow: $card-box-shadow-md;
  letter-spacing: 0.01rem;

  &.size-sm {
    height: 300px;
  }

  &.size-md {
    height: 336px;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    padding: px2rem(12);
  }

  .badge {
    position: absolute;
    left: $badge-left-card;
    top: $badge-top-card;
    font-weight: $font-weight-600;
    font-size: $otoklix-font-size-xxs;
    z-index: 1;
  }

  img.card-img-top {
    object-fit: cover;
  }

  img.workshop-icon {
    width: px2rem(16);
    height: px2rem(16);
  }

  .icon {
    width: px2rem(12);
    height: px2rem(12);
    min-height: unset;
  }

  .icon-text {
    font-size: $otoklix-font-size-xs;
  }

  .overline {
    font-size: px2rem(10);
    line-height: px2rem(15);
    max-height: px2rem(30); // 2 * 15
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .title {
    font-size: px2rem(14);
    line-height: px2rem(21);
    max-height: px2rem(42); // 2 * 21
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  
  .text-city {
    font-size: px2rem(14);
    line-height: px2rem(21);
    max-height: px2rem(42); // 2 * 21
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: $placeholder;
    font-size: px2rem(8);
    margin-right: px2rem(4);
  }

  .btn-title {
    font-size: px2rem(8);
    line-height: px2rem(12);
    font-weight: 700;
    text-decoration: underline;
  }

  .btn-subtitle {
    font-size: px2rem(8);
    line-height: px2rem(12);
    color: $dark;
  }

  .price-section {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    letter-spacing: 0.2px;
  }

  .text-price-discount {
    color: $placeholder;
    font-size: px2rem(8);
    margin-right: px2rem(4);
  }

  .text-price {
    color: $secondary;
    font-weight: 700;
    font-size: $otoklix-font-size-xs;
  }

  .add-car {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    background: #F7F7FC;
    border: 1px solid #EFF0F7;
    box-sizing: border-box;
    border-radius: 8px;
  }

  &.home {
    font-size: px2rem(12);
    height: unset;

    .title {
      font-size: px2rem(12);
      line-height: px2rem(18);
      max-height: px2rem(36);
      margin-bottom: px2rem(8);
    }

    .workshop-title,
    .workshop-subtitle {
      letter-spacing: 0.001em;
      font-size: px2rem(8);
      line-height: px2rem(14);
      max-height: px2rem(28);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    hr {
      background: $white-md;
      margin: px2rem(8) auto;
      opacity: 1;
    }
  }

  .click-image {
    bottom: unset;
    top: 32%;
  }
}

.card-vehicle {
  cursor: pointer;
  display: flex;
  align-items: center;

  min-height: px2rem(48);
  padding: px2rem(4) px2rem(12);
  font-size: $otoklix-font-size-xs;
  line-height: px2rem(18);

  border: 1px solid $white-md;
  box-shadow: $card-box-shadow-sm;

  .left-image {
    height: px2rem(25);
  }

  .right-image {
    width: px2rem(24);
  }

  .title {
    color: $dark;
  }

  .subtitle {
    color: $secondary;
    font-size: $otoklix-font-size-xs;
    font-weight: $font-weight-700;
  }

  .empty {
    color: $secondary;
    font-size: $otoklix-font-size-xs;
    font-weight: 500;
  }
}



.ribbon-discount {
  position: absolute;
  right: 0px;
}

.ribbon-text {
  position: absolute;
  left: 0px;
  top: 0;
  bottom: 0px;
  right: 0px;
  margin: 0 auto;
  font-size: 8px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  color: $danger;
}

.card-list-media {
  .title {
    font-size: $otoklix-font-size-md;
    color: $label;
    font-weight: 400;
  }

  .subtitle {
    font-size: $otoklix-font-size;
    color: $dark;
    font-weight: 600;
  }

  .text-right-link {
    font-size: $otoklix-font-size-md;
    color: $secondary;
    font-weight: 600;
    cursor: pointer;
  }

  .left-image {
    object-fit: cover;
  }
}

.card-review {
  border: 1px solid $white-md;
  border-radius: 16px;

  * {
    cursor: pointer;
  }

  &--carousel {
    width: 242px;
  }

  &--content {
    height: 110px;
  }

  &--username {
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.card-services {
  border-radius: $border-radius-sm;
  border: none;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.04);


  .usp-top {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    color: $primary;
    font-size: 9px;
    border-bottom: 1px solid #E6E6E4;
    border-radius: $border-radius-sm $border-radius-sm 0 0;
  }

  .usp-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EBF7ED;
    padding: 4px;
    font-size: 9px;
    border-radius: 0 0 $border-radius-sm $border-radius-sm;
  }

  .main-image {
    border-radius: $border-radius-sm;
    margin-right: px2rem(8);
    object-fit: cover;
  }

  .title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    font-size: $otoklix-font-size-md;
    line-height: 21px;
    letter-spacing: 0.75px;
  }
  
  .header-text {
    font-weight: normal;
    font-size: $otoklix-font-size-xxs;
    line-height: 15px;
    letter-spacing: 0.75px;
    color: $white-xl;
  }

  .discount-ribbon {
    position: absolute;
    top: 7px;
    left: -8px;
    padding: 2px 10px;
    background-color: $red;
    border-radius: 12px 12px 12px 0;
    z-index: 10;

    &:before {
      content: "";
      position: absolute;
      height: 0;
      width: 0;
      bottom: -8px;
      left: 0;
      border-top: 8px solid $red-dark;
      border-left: 8px solid transparent;
    }
  }

  .recommended-ribbon {
    position: absolute;
    top: 7px;
    left: -8px;
    padding: 2px 10px;
    background-color: #077D55 !important;
    border-radius: 12px 12px 12px 0;
    z-index: 10;

    &.--active {
      top: 30px;
    }
  
    &:before {
      content: "";
      position: absolute;
      height: 0;
      width: 0;
      bottom: -8px;
      left: 0;
      border-top: 8px solid #077D55;
      border-left: 8px solid transparent;
    }
  }

  .box-image {
    width: 80px;
    height: 80px;
    margin-right: 0.5rem;
    position: relative;

    .recommended-label {
      position: absolute;
      background-color: #077D55;
      border-radius: 8px;
      padding: 2px 10px;
      font-weight: 600 !important;
      bottom: -5%;
      left: 4px;
      width: fit-content;
    }

    .click-image {
      bottom: 8%;
    }
  }

  .discount-label {
    background-color: #FADCD9 !important;
    color: $red;
    border-radius: 16px;
    padding: 2px 6px;
    font-weight: 600 !important;
    width: fit-content;
    margin-left: 10px;
  }
}

.card-workshops-explore {
  .floating-button {
    position: absolute;
    right: 14px;
    top: 16px;
  }

  .left-content {
    margin-bottom: 1.5rem;

    .img-wrapper {
      position: relative;
      width: 112px;
      height: 112px;
      margin-right: px2rem(8);

      .rounded-image {
        border-radius: $border-radius-sm;
        object-fit: cover;
        object-position: center;
      }

      .badge-top-left {
        position: absolute;
        left: 0;
        top: 0.7rem;
        z-index: 1;
        font-size: 6px !important;
        padding: 0.1rem 0.3rem !important;
        font-weight: 600 !important;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;

        &.ws-explore {
          padding: 4px 8px !important;
        }
      }

      .rating-wrapper {
        position: absolute;
        bottom: -13px;
        left: 14%;
        right: 14%;
        text-align: center;
        background-color: #fff;
        border-radius: 100px;
        padding: 5px 10px;
        overflow-x: hidden;
        box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);

        &.ws-explore {
          right: unset;
          left: 50%;
          width: auto;
          transform: translateX(-50%);
          padding: 5px;

          .rating {
            font-size: 8px !important;
          }

          .total-review {
            font-size: 8px !important;
          }
        }
      }

      &.ws-explore{
        width: 80px;
        height: 80px;
      }
    }
  }

  .tags-ws-explore {
    font-size: 7px;
  }

  .card-ws-explore-button {
    font-size: 8px;
    padding: 2px 4px;
  }
  
  .time-ws-explore {
    font-weight: 600;
    color: #16A163;
    margin-bottom: 0;
    font-size: $otoklix-font-size-xxs;
  }

  .original-price{
    color: $placeholder;
    font-size: px2rem(10);
  }
  
  .per-volume-price {
    color: $black-md;
    font-size: px2rem(10);
  }

  .title {
    font-size: $otoklix-font-size-xs;
    line-height: px2rem(20);
    max-height: px2rem(40); // 2 * 15
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin: 0px;
    font-weight: 700;
    color: $black-lg;
  }

  .rating {
    font-size: $otoklix-font-size-xxs;
    font-weight: 600;
    color: #ff6400;
  }

  .total-review {
    font-size: $otoklix-font-size-xxs;
    font-weight: 400;
    color: $black-sm;
  }

  .info {
    font-size: 0.625rem;
    color: #676765;

    .address {
      // white-space: nowrap;
      // overflow: hidden;
      font-size: $otoklix-font-size-xxs;
      line-height: 1.3125rem;
      max-height: 2.625rem;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }

    .distance {
      color: #676765;
      font-weight: 500;
      font-size: 8px;
      margin: 0;

      .estimate {
        margin-left: 2px;
        color: #ACABA8;
      }
    }

    .time {
      font-weight: 600;
      color: #16A163;
      margin-bottom: 0;

      span {
        margin-right: 5px;
      }
    }
  }

  .pricing-section {

    .label {
      font-size: 8px;
      &.xs {
        font-size: 6px;
      }
    }

    .price {
      font-size: $otoklix-font-size;
      color: $primary;
      font-weight: 700;
      letter-spacing: 0.75px;
    }

    .point {
      color: $orange;
      font-weight: 700;
      font-size: 8px;
    }
  }

  .font-small {
    font-size: 0.625rem;
  }

  .row-wrap {
    flex-flow: row wrap;
  }

  .discount-label {
    background-color: #FADCD9 !important;
    color: $red;
    border-radius: 16px;
    padding: 2px 6px;
    font-weight: 600 !important;
    width: fit-content;
    margin-left: 10px;
  }

  .flagship-ribbon {
    position: absolute;
    top: 7px;
    left: -8px;
    padding: 2px 6px;
    background-color: $primary !important;
    border-radius: 12px 12px 12px 0;
    z-index: 10;
    font-size: 9px;
    font-weight: 600;
    line-height: 12px;
    color: #fff;

    &.--plus {
      background-color: $secondary !important;

      &:before {
        border-top: 8px solid #D45300 !important;
      }
    }
  
    &:before {
      content: "";
      position: absolute;
      height: 0;
      width: 0;
      bottom: -8px;
      left: 0;
      border-top: 8px solid #00207D !important;
      border-left: 8px solid transparent;
    }
  }

  .available-services-title {
    font-size: $otoklix-font-size-xxs;
    margin-bottom: 8px;
  }

  .available-services-wrapper {
    overflow: auto;
    
    .list-wrapper {
      display: flex;
      gap: 4px;
      width: fit-content;

      .card {
        border-radius: 8px;
        width: max-content;
  
        .card-body {
          padding: 4px;
          display: flex;
          align-items: center;
        
          img {
            border-radius: 4px;
            margin-right: 8px;
          }
      
          span {
            font-size: 10px;
            font-weight: 400;
            line-height: 16px;
          }
        }
      }
    }
  }
}

.card-recommend-workshop {
  position: relative;
  border-radius: $border-radius-xl;
  width: 152px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08);

  img {
    object-fit: cover;
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
  }

  .rating-label {
    position: absolute;
    background-color: $white-xl;;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    padding: 0 8px;
    top: 95px;
    left: 35px;
  }

  .card-body {
    .workshop-title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.625;
      height: 46px;
    }

    .workshop-address {
      display: block;
      max-width: 31px;
      margin-right: 2px;

      &.--full {
        max-width: 50px;
      }
    }

    .workshop-distance {
      margin-left: 2px;
    }

    .eta {
      font-size: 8px !important;
      color: #ACABA8 !important;
      margin-left: 2px;
      margin-top: 2px;
    }
  }

  .tag-ws {
    margin-top: 3px;
  }

  .tag-ws::-webkit-scrollbar {
    width: 0 !important
  }

  .tags-ws-recommend {
    font-size: 9px;
  }

  .flagship-ribbon {
    position: absolute;
    top: 7px;
    left: -8px;
    padding: 2px 12px;
    background-color: $primary;
    border-radius: 12px 12px 12px 0;
    z-index: 10;
    font-size: 9px;
    font-weight: 600;
    line-height: 12px;
    color: #fff;

    &.--plus {
      background-color: $secondary !important;

      &:before {
        border-top: 8px solid #D45300 !important;
      }
    }
  
    &:before {
      content: "";
      position: absolute;
      height: 0;
      width: 0;
      bottom: -8px;
      left: 0;
      border-top: 8px solid #00207D;
      border-left: 8px solid transparent;
    }
  }

}

.card-recommend-service {
  position: relative;
  width: 186px;

  .usp-top {
    background-color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 4px 0;
    color: $primary;
    font-size: 9px;
    border-bottom: 1px solid #E6E6E4;
    height: 20px;
  }

  .usp-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EBF7ED;
    padding: 4px;
    font-size: 9px;
    border-radius: 0 0 $border-radius-xl $border-radius-xl;
  }

  img {
    object-fit: cover;
  }

  .img-card-service {
    min-height: 140px;
    position: relative;
  }

  .card-body {
    .service-title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.625;
      height: 46px;
    }

    .service-subtitle {
      color: #676765;
      font-size: 10px;
      display: block;
    }

    .sub-price {
      color: #676765;
      font-size: 10px;
    }
  }

  .category-label {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .discount-ribbon {
    position: absolute;
    top: 10px;
    right: -8px;
    padding: 2px 10px 4px;
    background-color: $red;
    border-radius: 12px 12px 0px 12px;
  
    &:before {
      content: "";
      position: absolute;
      height: 0;
      width: 0;
      bottom: -8px;
      right: 0;
      border-top: 8px solid $red-dark;
      border-right: 8px solid transparent;
    }
  }

  .recommended-ribbon {
    position: absolute;
    top: 10px;
    right: -8px;
    padding: 2px 10px 4px;
    background-color: #077D55 !important;
    border-radius: 12px 12px 0px 12px;
  
    &:before {
      content: "";
      position: absolute;
      height: 0;
      width: 0;
      bottom: -8px;
      right: 0;
      border-top: 8px solid #077D55;
      border-right: 8px solid transparent;
    }
  }

  .recommended-label {
    position: absolute;
    background-color: #077D55;
    border-radius: 8px;
    padding: 2px 10px;
    font-weight: 600 !important;
    bottom: 48%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }

  .discount-label {
    background-color: #FADCD9 !important;
    color: $red;
    border-radius: 16px;
    padding: 2px 6px;
    font-weight: 600 !important;
    width: fit-content;
    margin-left: 10px;
  }
}

.category-label {
  color: $warning;
  background-color: $yellow-light;
  padding: 1px 5px;
  border-radius: 12px;
}
