//
// Base styles
//

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
  height: $card-height;
  word-wrap: break-word;
  background-color: $card-bg;
  background-clip: border-box;
  border: $card-border-width solid $card-border-color;
  @include border-radius($card-border-radius);

  > hr {
    margin-right: 0;
    margin-left: 0;
  }

  > .list-group {
    border-top: inherit;
    border-bottom: inherit;

    &:first-child {
      border-top-width: 0;
      @include border-top-radius($card-inner-border-radius);
    }

    &:last-child  {
      border-bottom-width: 0;
      @include border-bottom-radius($card-inner-border-radius);
    }
  }

  // Due to specificity of the above selector (`.card > .list-group`), we must
  // use a child selector here to prevent double borders.
  > .card-header + .list-group,
  > .list-group + .card-footer {
    border-top: 0;
  }
}

.card-body {
  // Enable `flex-grow: 1` for decks and groups so that card blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  flex: 1 1 auto;
  padding: $card-spacer-y $card-spacer-x;
  color: $card-color;
}

.card-title {
  margin-bottom: $card-spacer-x;
}

h2.card-title {
  font-size: 1rem;
  line-height: 1.5;
}

.card-subtitle {
  margin-top: -$card-spacer-x / 2;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link {
  &:hover {
    text-decoration: none;
  }

  + .card-link {
    margin-left: $card-spacer-x;
  }
}

//
// Optional textual caps
//

.card-header {
  padding: $card-cap-padding-y $card-cap-padding-x;
  margin-bottom: 0; // Removes the default margin-bottom of <hN>
  color: $card-cap-color;
  background-color: $card-cap-bg;
  border-bottom: $card-border-width solid $card-border-color;

  &:first-child {
    @include border-radius($card-inner-border-radius $card-inner-border-radius 0 0);
  }
}

.card-footer {
  padding: $card-cap-padding-y $card-cap-padding-x;
  color: $card-cap-color;
  background-color: $card-cap-bg;
  border-top: $card-border-width solid $card-border-color;

  &:last-child {
    @include border-radius(0 0 $card-inner-border-radius $card-inner-border-radius);
  }
}


//
// Header navs
//

.card-header-tabs {
  margin-right: -$card-cap-padding-x / 2;
  margin-bottom: -$card-cap-padding-y;
  margin-left: -$card-cap-padding-x / 2;
  border-bottom: 0;

  @if $nav-tabs-link-active-bg != $card-bg {
    .nav-link.active {
      background-color: $card-bg;
      border-bottom-color: $card-bg;
    }
  }
}

.card-header-pills {
  margin-right: -$card-cap-padding-x / 2;
  margin-left: -$card-cap-padding-x / 2;
}

// Card image
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $card-img-overlay-padding;
  @include border-radius($card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
}

.card-img,
.card-img-top {
  @include border-top-radius($card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  @include border-bottom-radius($card-inner-border-radius);
}


//
// Card groups
//

.card-group {
  // The child selector allows nested `.card` within `.card-group`
  // to display properly.
  > .card {
    margin-bottom: $card-group-margin;
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-flow: row wrap;
    // The child selector allows nested `.card` within `.card-group`
    // to display properly.
    > .card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      margin-bottom: 0;

      + .card {
        margin-left: 0;
        border-left: 0;
      }

      // Handle rounded corners
      @if $enable-rounded {
        &:not(:last-child) {
          @include border-end-radius(0);

          .card-img-top,
          .card-header {
            // stylelint-disable-next-line property-disallowed-list
            border-top-right-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            // stylelint-disable-next-line property-disallowed-list
            border-bottom-right-radius: 0;
          }
        }

        &:not(:first-child) {
          @include border-start-radius(0);

          .card-img-top,
          .card-header {
            // stylelint-disable-next-line property-disallowed-list
            border-top-left-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            // stylelint-disable-next-line property-disallowed-list
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }
}

.compatible-tip {
  border-radius: 8px 8px 0px 8px;
  padding: 2px 6px 2px 6px;
  background-color: #077D55;
  color: #fff;
  width: fit-content;
  font-size: 9px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0px;
  position: absolute;
  top: 6px;
  right: -4px;
  z-index: 1;
}

.compatible-tip-downed {
  border-radius: 8px 8px 0px 8px;
  padding: 2px 6px 2px 6px;
  background-color: #077D55;
  color: #fff;
  width: fit-content;
  font-size: 9px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0px;
  position: absolute;
  top: 38px;
  right: -4px;
  z-index: 1;
}

.compatible-tip::before, .compatible-tip-downed::before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  bottom: -6px;
  right: 0;
  border-top: 6px solid #077D55 !important;
  border-right: 4px solid transparent;
}

.click-image {
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  background-color: #fff;
  color: $black-lg;
  box-shadow: 0px 2px 4px 0px rgba(26, 25, 25, 0.08), 0px 0px 6px 0px rgba(26, 25, 25, 0.02);
  border-radius: 16px;
  padding: 2px 6px;
  font-size: $otoklix-font-size-xxs;
  position: absolute;
  bottom: 20%;
  left: 0;
  right: 0;
  margin: auto;
}

.on-cart-text {
  border-radius: 12px;
  background-color: $green-light;
  color: $green-dark;
  padding: 3px 8px;
  font-size: $otoklix-font-size-xxs;
  width: 100%;
  text-align: center;
}

.on-cart-text-horizontal {
  border-radius: 12px;
  background-color: $green-light;
  color: $green-dark;
  padding: 3px 8px;
  font-size: $otoklix-font-size-xxs;
  width: fit-content;
  height: fit-content;
  text-align: center;
}

.extras-button {
  border-radius: 18px;
  padding: 3px 8px;
  width: fit-content;
}

.product-description-list {
  display: flex;
  flex-direction: column;
  font-size: $otoklix-font-size-xxs;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #898987;
}
