.box-icon {
  width: $container-icon;
  text-align: center;
  display: table;
  padding: 0;
  position: relative;

  .icon {
    position: relative;
    padding: $spacing-icon;
    height: $container-icon;
    width: $container-icon;

    img {
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
  
  .icon-text {
    margin-top: $icon-text-spacing;
    font-size: $otoklix-font-size-sm;
    font-weight: 500;
    color: $label;
  }

  .badge-top-right {
    position: absolute;
    right: $badge-right;
    top: $badge-top;
    z-index: 1;
  }
}

.box-icon-sm {
  width: $container-icon-sm;

  .icon {
    height: unset;
    width: unset;
    min-height: $container-icon-sm;
  }
}

.box-icon-md {
  width: $container-icon-md;

  .icon {
    height: $container-icon-md;
    width: $container-icon-md;
    min-height: $container-icon-md;
  }
}

.box-icon-card-image {
  width: $container-icon-xs;
  min-height: $container-icon-xxs;
  
  .icon {
    height: $container-icon-sm;
    width: $container-icon-xs;
    min-height: $container-icon-xxs;
  }
}
