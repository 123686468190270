.loader-spinner{
    color: #0d6efd!important;
}
.wrapper-content {
    // padding: 29px 69px 29px 69px!important;
    background-color: #F8F9FA!important;
    .page-title-box {
        padding: 0px 0 25px !important;
    }
}
.wrapper-title {
    color: #343A40!important;
    font-size: 32px!important;
    font-weight: bold!important;
    line-height: 39px!important;
}
.tabs {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 5px 24px;
}
.tab-item {
    padding: 10px;
    background-color: #DEE2E6!important;
    border-radius: 6px;
    text-align: center;
    cursor: pointer;
    color: #6C757D!important;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    &:hover, &.active  {
        background-color: #0d6efd!important;
        color: #FFFFFF!important;
    }
    &.disabled {
        cursor: not-allowed!important;
    }
}
.card-custom{
    border: 0px!important;
    border-radius: 6px!important;
    padding: 30px 49px!important;
    margin: 0px!important;
}
.none-border-radius-top{
    border-radius: 0px 0px 6px 6px!important;
}
.card-custom-p-15-15{
    padding: 15px 15px!important;
}
.wrapper-slider-tabs{
    position: relative;
    margin-top: -15px;
    max-width: 100%;
    overflow-x: hidden;
	overflow-y: hidden;
    .slider-tabs{
        padding: 0px!important;
        white-space: nowrap;
        overflow-x: hidden;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        .tab-items-slider{
            display: inline-block;
            justify-content: center;
            align-items: center;
            padding: 8px 16px;
            gap: 8px;
            height: 33px;
            background: rgba(222, 226, 230, 0.2);
            border-radius: 6px;
            border: 1px solid rgba(222, 226, 230, 0.2);
            color: #6C757D;
            font-weight: 500;
            font-size: 14px;
            cursor: pointer;
            line-height: 17px;
            &:hover, &.active{
                background-color: rgba(13, 110, 253, 0.2);
                border: 1px solid #0D6EFD;
                color: #0D6EFD;
            }
            &.disabled{
                cursor: not-allowed!important;
            }
        }
        .tab-item-slider-fisrt{
            margin: 8px 8px 8px 0px;
        }
        .tab-item-slider{
            margin: 8px;
        }
        .tab-item-slider-last{
            margin: 8px 0px 8px 8px;
        }
    }
}

.button-slide-inventory{
    background-position: center center;
    background-size: 25px;
    background-repeat: no-repeat;
    cursor: pointer;
    position: absolute;
    display: block;
    z-index: 100!important;
    top: 12px;
    width: 25px!important;
    height: 25px!important;
}

.button-left-slide{
    background-image: url('../assets/icons/left-arrow-slide.svg');
    left: 0px;
    &:hover{
        background-size: 35px;
        top: 10px;
        width: 30px!important;
        height: 30px!important;
    }
}

.button-right-slide{
    background-image: url('../assets/icons/right-arrow-slide.svg');
    right: 0px;
    &:hover{
        background-size: 35px;
        top: 10px;
        width: 30px!important;
        height: 30px!important;
    }
}

.button-left-slide:hover{
    background-size: 35px;
    top: 10px;
    width: 30px!important;
    height: 30px!important;
}

.button-right-slide:hover{
    background-size: 35px;
    top: 10px;
    width: 30px!important;
    height: 30px!important;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none!important;
}
  
.react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none!important; /* IE and Edge */
    scrollbar-width: none!important; /* Firefox */
}

.section-action {
    display: flex;
    flex-flow: row-reverse wrap;
    align-items: center;
    gap: 0.4rem 0.5rem;
    .button-action{
        width: 135px;
        // padding: 5px 45px;
        border-radius: 3px;
        text-align: center;
        letter-spacing: 0.466667px;
        box-shadow: none !important;
        border: unset!important;
        font-weight: 500!important;
        &:disabled {
            opacity: 0.4;
            cursor: not-allowed!important;
        }
    }
    .primary{
        color: #FFFFFF;
        background: #7680ff;
        &:hover{
            opacity: 0.8!important;
            color: #FFFFFF;
            background: #7680ff!important;
        }
        &:disabled {
            opacity: 1!important;
            cursor: not-allowed!important;
        }
    }
    .export{
        color: #FFFFFF;
        background: #0d6efd;
        &:hover{
            opacity: 0.8!important;
            color: #FFFFFF;
            background: #0d6efd!important;
        }
        &:disabled {
            opacity: 1!important;
            cursor: not-allowed!important;
        }
    }
    .add{
        color: #FFFFFF;
        background: #FD7E14;
        border-color: #FD7E14;
        &:hover{
            opacity: 0.8!important;
            color: #FFFFFF;
            background: #FD7E14!important;
        }
        &:disabled {
            opacity: 1!important;
            cursor: not-allowed!important;
        }
    }
    .save{
        color: #FFFFFF!important;
        background: rgba(13, 110, 253, 1)!important;
        &:hover  {
            background: rgba(13, 110, 253, 0.8)!important;
        }
    }
    .cancel{
        color: #0D6EFD!important;
        background: rgba(128, 189, 255, 0.5)!important;
        &:hover{
            color: #0D6EFD!important;
            background: rgba(128, 189, 255, 0.4)!important;
        }
    }
    .activate{
        color: #0D6EFD;
        background: #CFE2FF;
        &:hover{
            opacity: 0.8!important;
            color: #0D6EFD!important;
            background: #CFE2FF!important;
        }
    }
    .inactive{
        color: #DC3545;
        background: #F8D7DA;
        &:hover{
            opacity: 0.8!important;
            color: #DC3545!important;
            background: #F8D7DA!important;
        }
    }
    .activate, .inactive {
        &:disabled {
            opacity: 1!important;
            color: #ADB5BD!important;
            background: #E9ECEF!important;
        }
    }

    
}
.button-add-stakeholder{
    float: right;
    width: 100%;
    height: 48px;
    line-height: 31px;
    cursor: pointer;
    font-family: 'Poppins';
    color: #0A58CA;
    font-weight: 600;
    font-size: 16px;
    line-height: 50px;
    background: rgba(207, 226, 255, 1);
    border-radius: 8px;
    border: 1px dashed rgba(10, 88, 202, 1);
    text-align: center;
    letter-spacing: 0.466667px;
    -webkit-user-select:none;
    -moz-user-select:none;
    user-select:none;
    &:hover  {
        background: rgba(207, 226, 255, 0.8);
        border: 1px dashed rgba(10, 88, 202, 0.8);
    }
}

.alert-notification{
    position: fixed;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    margin: auto;
    z-index: 1051;
    .success{
        background-color: #CFE2FF!important;
        border: 1px solid #B6D4FE!important;
        border-radius: 4px!important;
        color: #084298!important;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }
    .fail{
        background-color: #F8D7DA!important;
        border: 1px solid #F5C2C7!important;
        border-radius: 4px!important;
        color: #842029!important;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }
    .icon{
        margin-right: 8px;
    }
    .text{
        font-weight: 400;
        font-size: 14px;
        position: relative;
        top: 1px;
    }
}
.modal-confirmation{
    .modal-content {
        background: #FCFCFC!important;
        border-radius: 8px!important;
        border: unset!important;
    }
    .modal-body {
        padding: 20px 30px!important;
        letter-spacing: 1px;
    }
    .body{
        .header{
            font-weight: 600;
            font-size: 18px;
            text-align: center;
            color: #14142B;
        }
        .sub-header{
            font-weight: 500;
            font-size: 14px;
            line-height: 25px;
            text-align: center;
            color: #4E4B66;
            margin-top: 12px;
        }
        .action{
            display: flex;
            justify-content: center;
            padding: 0px;
            gap: 40px;
            margin-top: 28px;
            .button-action-modal{
                width: 135px;
                height: 40px;
                border-radius: 8px;
                font-weight: 600;
                font-size: 12px;
                letter-spacing: 1px;
                box-shadow: none !important;
            }
            .primary{
                border: 2px solid #002EB4!important;
                background: #002EB4!important;
                &:hover  {
                    background: #FFFFFF!important;
                    color: #002EB4!important;
                }
            }
            .danger{
                border: 2px solid #ED2E2E!important;
                background: #ED2E2E!important;
                &:hover  {
                    background: #FCFCFC!important;
                    color: #ED2E2E!important;;
                }
            }
        }
    }
}

.button-outline{
    // width: 80px;
    height: 35px;
    // border-radius: 8px;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 1px;
    box-shadow: none !important;
}
.red{
    border: 2px solid #ED2E2E!important;
    background: #ED2E2E!important;
    &:hover  {
        background: #FCFCFC!important;
        color: #ED2E2E!important;;
    }
}
.purple-outline{
    // color: #ffffff;
    background: #0d6efd!important;
    border: 2px solid #0d6efd!important;
    &:hover{
        background: #FCFCFC!important;
        color: #0d6efd!important;
    }
}

.modal-custom{
    .modal-content {
        background: #FCFCFC!important;
        border-radius: 8px!important;
        border: unset!important;
    }
    .modal-body {
        padding: 20px 30px!important;
        letter-spacing: 1px;
    }
    .body{
        .header{
            font-weight: 600;
            font-size: 18px;
            text-align: center;
            color: #14142B;
        }
    }
    .modal-title {
        color: rgba(26, 25, 25, 1) !important;
        font-weight: 600 !important;
        font-size: 16px;
        // padding-bottom: 10px;
        display: flex;
        .close-icon-box {
          display: block;
          .close-icon {
            position: absolute;
            margin: 60px 30px;
            top: 20px;
            right: 15px;
            height: 12px;
          }
        }
    }
}

.modal-form{
    .modal-content {
        background: #FCFCFC;
        border-radius: 6px!important;
        align-items: center;
        width: 484px;
        display: flex;
        flex-direction: column;
    }
    .modal-body {
        padding: unset!important;;
    }
    .body{
        background: #FCFCFC;
        border-radius: 8px!important;
        align-items: center;
        width: 484px;
        display: flex;
        flex-direction: column;
        .header{
            font-weight: 700;
            font-size: 24px;
            text-align: center;
            letter-spacing: 1px;
            color: #000000;
        }
        .content{
            width: 484px;
            font-weight: 400!important;
            color: #68717A!important;
            text-align: left;
            padding: 16px 32px!important;
            .input-text{
                color: #052C65!important;
                background-color: #FFFFFF!important;
                border: 1px solid #CED4DA;
            }
            .input-text:disabled{
                color: #052C65!important;
                background-color: #DEE2E6!important;
                border: 1px solid #CED4DA;
            }
        }
        .action{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0px;
            gap: 40px;
            width: 484px;
            .button-action{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 7px 48px;
                width: 149px;
                color: #FFFFFF;
                background: rgba(13, 110, 253, 1);
                border-radius: 2px;
                border: unset!important;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 1px;
            }
            .button-cancel{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 7px 48px;
                width: 149px;
                color: #0D6EFD;
                background: rgba(128, 189, 255, 0.5);
                border: unset!important;
                border-radius: 2px;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 1px;
            }
            .button-action:hover{
                background: rgba(13, 110, 253, 0.8);
            }
            .button-cancel:hover{
                background: rgba(128, 189, 255, 0.4);
            }
            .button-action:disabled{
                cursor: auto!important;
            }
            .button-cancel:disabled{
                cursor: auto!important;
            }
        }
    }
}

.modal-preview-csv{
    .modal-content {
        background: unset;
        border-radius: unset;
        border: unset;
        align-items: center;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .modal-body {
        padding: unset!important;
        padding-top: 1rem!important;
    }
    .body{
        background: #FCFCFC;
        border-radius: 8px!important;
        align-items: center;
        width: 1200px;
        display: flex;
        flex-direction: column;
        .header{
            font-weight: 700;
            font-size: 24px;
            text-align: center;
            letter-spacing: 1px;
            color: #000000;
        }
        .content{
            width: 1200px;
            font-weight: 400!important;
            color: #68717A!important;
            text-align: left;
            padding: 16px 32px!important;
            .input-text{
                color: #052C65!important;
                background-color: #FFFFFF!important;
                border: 1px solid #CED4DA;
            }
            .input-text:disabled{
                color: #052C65!important;
                background-color: #DEE2E6!important;
                border: 1px solid #CED4DA;
            }
        }
        .action{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0px;
            gap: 40px;
            width: 1200px;
            .button-action{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 7px 48px;
                width: 149px;
                color: #FFFFFF;
                background: rgba(13, 110, 253, 1);
                border-radius: 2px;
                border: unset!important;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 1px;
            }
            .button-cancel{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 7px 48px;
                width: 149px;
                color: #0D6EFD;
                background: rgba(128, 189, 255, 0.5);
                border: unset!important;
                border-radius: 2px;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 1px;
            }
            .button-action:hover{
                background: rgba(13, 110, 253, 0.8);
            }
            .button-cancel:hover{
                background: rgba(128, 189, 255, 0.4);
            }
            .button-action:disabled{
                cursor: auto!important;
            }
            .button-cancel:disabled{
                cursor: auto!important;
            }
        }
    }
}

.modal-items{
    .modal-content {
        background: #FCFCFC;
        border-radius: 4px!important;
        align-items: center;
        width: 610px;
        display: flex;
        flex-direction: column;
    }
    .modal-body {
        padding: unset!important;;
    }
    .body{
        background: #FCFCFC;
        border-radius: 8px!important;
        align-items: center;
        width: 610px;
        display: flex;
        flex-direction: column;
        .header{
            width: 610px;
            height: 44px;
            font-weight: 500;
            font-size: 16px;
            line-height: 44px;
            text-align: left;
            letter-spacing: 1px;
            color: #14142B;
            padding-left: 16px!important;
            padding-right: 16px!important;
            border-bottom: 2px solid #E9ECEF;
        }
        .button-close{
            background-image: url('../assets/icons/close.svg');
            background-position: center center;
            background-size: 13px;
            background-repeat: no-repeat;
            cursor: pointer;
            position: absolute;
            top: 18px;
            right: 18px;
            width: 8px!important;
            height: 8px!important;
        }
        .content{
            width: 610px;
            font-weight: 500!important;
            font-size: 13px!important;
            letter-spacing: 1px!important;
            color: #052C65!important;
            text-align: left;
            padding: 16px!important;
            .content-items{
                height: 270px;
                overflow-y: auto;
            }
            .search-field{
                height: 42px!important;
                background-color: #FFFFFF;
                border: 1px solid #E0E7ED;
                border-radius: 8px;
                background-image: url('../assets/icons/search.svg');
                background-repeat: no-repeat;
                background-position: 13px 12px;
                padding: 8px 8px 8px 40px;
                color: #0d6efd!important;
                &:focus, &.filled{
                    border: 1px solid #0d6efd!important;
                    background-image: url('../assets/icons/search-purple.svg');
                }
            }
        }
        .footer{
            color: #212529!important;
            width: 610px;
            padding: 7px 16px 2px 16px!important;
            border-top: 2px solid #E9ECEF;
            position: unset!important;
            .section-pagination{
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                gap: 0.5rem 0;
                .section-info-paging{
                    width: 35%;
                    text-align: left;
                    font-size: 12px;
                    padding: 10px 0;
                }
                .section-paging{
                    width: 65%;
                    text-align: right;
                }
                .custom-pagination {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                    overflow: hidden;
                    li {
                      float: left;
                      cursor: pointer;
                      padding: 10px 0;
                      a {
                        margin: 0 4px;
                        border: unset;
                        font-size: 12px;
                        color: $soft-gray;
                        padding: 8px 8px;
                        color: #333333;
                        background-color: #F8F9FA;
                        border-radius: 8px;
                        border-color: unset;
                        border: unset;
                        font-weight: 400;
                        &:hover {
                            color: $primary;
                            background-color: $soft-purple;
                        }
                      }
                      &.selected {
                        a {
                          color: #FFFFFF;
                          background-color: #0d6efd;
                        }
                      }
                    }
                    .next-section, .prev-section{
                        background-color: #F8F9FA;
                        border-radius: 8px;
                        a {
                            margin: unset;
                            padding: 8px;
                            background-image: url('../assets/icons/right-arrow-paginate.svg');
                            background-repeat: no-repeat;
                            background-position: center;
                            &:hover {
                                color: $primary;
                                background-color: $soft-purple;
                            }
                        }
                    }
                    .next-section{
                        a {
                            background-image: url('../assets/icons/right-arrow-paginate.svg');
                        }
                    }
                    .prev-section{
                        a {
                            background-image: url('../assets/icons/left-arrow-paginate.svg');
                        }
                    }
                  }
            }
        }
    }
}

.action{
    display: flex;
    justify-content: center;
    padding: 0px;
    gap: 40px;
    margin-top: 28px;
    .button-action-modal{
        width: 135px;
        height: 40px;
        border-radius: 8px;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 1px;
        box-shadow: none !important;
    }
    .primary{
        border: 2px solid #002EB4!important;
        background: #002EB4!important;
        &:hover  {
            background: #FFFFFF!important;
            color: #002EB4!important;
        }
    }
    .danger{
        border: 2px solid #ED2E2E!important;
        background: #ED2E2E!important;
        &:hover  {
            background: #FCFCFC!important;
            color: #ED2E2E!important;;
        }
    }
}

.input-emoji{
    background-image: none!important;
}
.input-bg-calender{
    background-image: url('../assets/icons/calender.svg')!important;
    background-repeat: no-repeat!important;
    background-position: 10px 9px!important;
    padding: 0px 0px 0px 33px;
    padding-right: 10px!important;
}
.button-emoji{
    position: absolute;
    background-repeat: no-repeat;
    background-position: 100%;
    width: 30px;
    height: 20px;
    cursor: pointer;
    right: 20px;
    top: 7px;
    padding: 0px;
    margin: 0px;
}
.show-emoji{
    background-image: url('../assets/icons/emoji.svg');
}
.hide-emoji{
    background-image: url('../assets/icons/close-emoji.svg');
}
.popup-emoji{
    position: absolute;
    z-index: 100;
    right: 10px;
    top: 35px;
}

.section-filters{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 0.4rem 0.5rem;
    .text-field-filter{
        height: 40px!important;
        background-color: #FFFFFF;
        border: 1px solid #E0E7ED;
        border-radius: 8px;
        background-image: url('../assets/icons/search.svg');
        background-repeat: no-repeat;
        background-position: 13px 12px;
        padding: 8px 8px 8px 40px;
        color: #0d6efd!important;
        font-weight: 600!important;
        &:focus, &.filled{
            border: 1px solid #0d6efd!important;
            background-image: url('../assets/icons/search-purple.svg');
        }
        &:disabled {
            background-color: #FFFFFF!important;
        }
    }
    .text-field-range-price-filter{
        position: relative;
        height: 40px;
        padding: 8px;
        background: #FFFFFF;
        border: 1px solid #E0E7ED;
        border-radius: 8px;
        color: #BDBDBD;
        line-height: 20px;
        .nominal{
            color: #0d6efd!important;
            font-weight: 600!important;
        }
        &:hover{
          cursor: text;
        }
        &.focus, &.filled{
            border: 1px solid #0d6efd!important;
        }
    }
    .content-range-price{
        position: absolute;
        width: 342px;
        left: 0px;
        top: 48px;
        background: #fff;
        box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);
        border: 0.5px solid #0d6efd;
        border-radius: 6px;
        padding: 24px 16px;
        z-index: 100;
        .wrapper-range-price{
            display: flex;
            flex-direction: row;
            align-items: center;
            .input-range-price{
                padding: 8px 5px;
                width: 100%;
                height: 40px;
                background: #FFFFFF;
                border: 1px solid #E0E7ED;
                border-radius: 8px;
                color: #0d6efd!important;
                font-weight: 600!important;
                &:focus, &.filled{
                    border: 1px solid #0d6efd!important;
                }
            }
            .separator{
                margin: 8px;
                width: 6px;
                border: 1px solid #ADB5BD;
            }
        }
        .button-apply-range-price{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 10px;
            width: 100%;
            height: 33px;
            margin-top: 24px;
            background: #E0CFFC;
            border: 1px solid #0d6efd;
            border-radius: 6px;
            color: #0d6efd;
            font-weight: 700;
            text-align: center;
            letter-spacing: 0.466667px;
            cursor: pointer;
            &:hover{
                opacity: 0.8;
            }
        }
    }
    .select-text-field-filter{
        display: flex;
        height: 40px;
        border: 1px solid #E0E7ED;
        border-radius: 8px;
        &.filled{
            border: 1px solid #0d6efd!important;
            .input-select-text-field-filter{
                border-left: 1px solid #0d6efd!important;
            }
        }
        .select-select-text-field-filter{
            width: 30%;
            height: 38px!important;
        }
        .input-select-text-field-filter{
            width: 70%;
            height: 38px!important;
            border-radius: 0px 8px 8px 0px;
            border: unset!important;
            background-color: #FFFFFF;
            border-left: 1px solid #E0E7ED!important;
            background-image: url('../assets/icons/search.svg');
            background-repeat: no-repeat;
            background-position: 13px 12px;
            padding: 8px 8px 8px 40px;
            color: #0d6efd!important;
            font-weight: 600!important;
            &.filled{
                background-image: url('../assets/icons/search-purple.svg');
            }
        }
    }
    .text-select-field-filter{
        display: flex;
        flex-direction: row-reverse;
        height: 40px;
        border: 1px solid #E0E7ED;
        border-radius: 8px;
        &.filled{
            border: 1px solid #0d6efd!important;
            .input-text-select-field-filter{
                border-left: 1px solid #0d6efd!important;
            }
        }
        .select-text-select-field-filter{
            width: 30%;
            height: 38px!important;
        }
        .input-text-select-field-filter{
            width: 70%;
            height: 38px!important;
            border-radius: 8px 0px 0px 8px;
            border: unset!important;
            background-color: #FFFFFF;
            border-right: 1px solid #E0E7ED!important;
            background-image: url('../assets/icons/search.svg');
            background-repeat: no-repeat;
            background-position: 13px 12px;
            padding: 8px 8px 8px 40px;
            color: #0d6efd!important;
            font-weight: 600!important;
            &.filled{
                background-image: url('../assets/icons/search-purple.svg');
            }
        }
    }
    .react-datepicker-wrapper{
        width: 215px;
        .date-field-filter{
            width: 100%;
            height: 40px;
            border: 1px solid #E0E7ED;
            background-color: #FFFFFF;
            border-radius: 8px;
            background-image: url('../assets/icons/calender.svg');
            background-repeat: no-repeat;
            background-position: 13px 12px;
            padding: 8px 8px 8px 40px;
            color: #0d6efd!important;
            font-weight: 600!important;
            &:hover{
                cursor: pointer;
            }
            &:focus, &.focus, &.filled{
                border: 1px solid #0d6efd!important;
                background-image: url('../assets/icons/calender-purple.svg');
            }
            &:disabled{
                background-color: #FFFFFF!important;
                cursor: unset;
            }
        }
    }
    
    .react-datepicker__close-icon{
        &::after{
            background-color: #0d6efd;
        }
    }
    .react-datepicker__tab-loop{
        position: absolute;
    }
    .react-datepicker-popper{
        padding-top: 7px;
        .react-datepicker{
            padding-top: 15px;
            background-color: #FFFFFF;
            border: 0.5px solid #0d6efd;
            box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);
            border-radius: 6px;
        }
        .react-datepicker__triangle{
            &::after, &::before{
                border: unset;
                content: unset;
            }
        }
        .react-datepicker__navigation{
            top: 14px;
        }
        .react-datepicker__navigation.react-datepicker__navigation--previous{
            left: 0px;
        }
        .react-datepicker__navigation.react-datepicker__navigation--next{
            right: 0px;
        }
        .react-datepicker__month-container{
            padding: 0px 10px 0px 10px
        }
        .react-datepicker__header{
            background-color: #FFFFFF;
            border-bottom: unset;
            padding: 0px;
            .react-datepicker__current-month{
                margin: 0px 10px;
                font-size: 14px;
                font-family: sans-serif;
                letter-spacing: 0.5px;
                color: #212529;
            }
            .react-datepicker__day-names{
                margin-bottom: 5px;
            }
            .react-datepicker__day-name{
                font-weight: 600;
            }
            .react-datepicker__header__dropdown--select{
                padding-bottom: 35px;
                border-bottom: 1px solid #DEE2E6;
                margin-top: 10px;
            }
            .react-datepicker__month-dropdown-container--select{
                position: absolute;
                left: 0;
            }
            .react-datepicker__year-dropdown-container--select{
                position: absolute;
                right: 0;
            }
            .react-datepicker__month-select, .react-datepicker__year-select{
                border: unset;
                color: #0d6efd;
                font-weight: 600;
                border: 1px solid #0d6efd;
                height: 25px;
                border-radius: 6px;
                cursor: pointer;
            }
            .select-items{
                display: block;
            }
            .select-hide {
                display: none!important;
              }
        }
        .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
            font-family: sans-serif;
            color: #212529;
            font-weight: 500;
            margin: 2px 0px;
            width: 2rem;
            line-height: 2.5rem;
        }
        .react-datepicker__month{
            margin: 0rem 0.4rem 0.4rem 0.4rem;
            .react-datepicker__week{
                .react-datepicker__day{
                    &:hover{
                        color: #212529;
                        background-color: #E0CFFC;
                    }
                }
                .react-datepicker__day--today{
                    font-weight: 700;
                }
                .react-datepicker__day--disabled{
                    color: #CED4DA;
                    &:hover{
                        background-color: #CED4DA;
                        color: #212529
                    }
                }
                .react-datepicker__day.react-datepicker__day--keyboard-selected {
                    background: none;
                    color: black;
                }
                .react-datepicker__day.react-datepicker__day--selected {
                    border-radius: 6px;
                    color: #FFFFFF;
                    background-color: #0d6efd;
                    &:hover{
                        color: #FFFFFF;
                        background-color: #0d6efd;
                    }
                }
                .react-datepicker__day.react-datepicker__day--in-range, .react-datepicker__day.react-datepicker__day--in-selecting-range{
                    color: #212529;
                    background-color: #E0CFFC;
                    border-radius: 0px;
                }
                .react-datepicker__day.react-datepicker__day--selecting-range-start, .react-datepicker__day.react-datepicker__day--range-start, .react-datepicker__day.react-datepicker__day--range-end {
                    border-radius: 6px 0px 0px 6px;
                    color: #FFFFFF;
                    background-color: #0d6efd;
                    &:hover{
                        color: #FFFFFF;
                        background-color: #0d6efd;
                    }
                }
                .react-datepicker__day.react-datepicker__day--range-end {
                    border-radius: 0px 6px 6px 0px;
                    // background-color: #4cc048;
                }
                .react-datepicker__day.react-datepicker__day--range-start.react-datepicker__day--range-end {
                    border-radius: 6px;
                }

                //outside month
                .react-datepicker__day.react-datepicker__day--outside-month{
                    color: #CED4DA;
                    &:hover{
                        background-color: #CED4DA;
                        color: #212529
                    }
                }
                .react-datepicker__day.react-datepicker__day--in-selecting-range.react-datepicker__day--outside-month, .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--outside-month{
                    background-color: #CED4DA;
                    color: #212529;
                }
                .react-datepicker__day.react-datepicker__day--selecting-range-start.react-datepicker__day--outside-month, .react-datepicker__day.react-datepicker__day--range-start.react-datepicker__day--outside-month, .react-datepicker__day.react-datepicker__day--range-end.react-datepicker__day--outside-month {
                    color: #FFFFFF;
                    background-color: #838485;
                    &:hover{
                        color: #FFFFFF;
                        background-color: #838485;
                    }
                }
                //end of outside month
            }
        }
        .react-datepicker__today-button {
            background-color: unset;
            border-top: 1px solid #DEE2E6;
            font-weight: 400;
            font-size: 14px;
            color: #0d6efd;
            font-family: sans-serif;
            letter-spacing: 0.5px;
            margin: 0px 10px 0px 10px;
            padding: 5px 0px 8px 0px;
            &:hover{
                font-weight: 600;
            }
        }
    }
    .arrow-active-select, .arrow-inactive-select{
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: center;
    }
    .arrow-active-select{
        background-image: url('../assets/icons/dropdown-indicator-inactive.svg');
    }
    .arrow-inactive-select{
        background-image: url('../assets/icons/dropdown-indicator-active.svg');
    }
    .button-reset-filter{
        width: 100px;
        cursor: pointer; 
        background: #E0CFFC!important;
        border: 1px solid #0d6efd!important;
        border-radius: 3px;
        color: #0d6efd!important;
        font-weight: 700;
        text-align: center;
        letter-spacing: 0.466667px;
        box-shadow: none !important;
        &:hover{
            opacity: 0.8!important;
        }
        &:disabled {
            opacity: 0.4;
            cursor: not-allowed!important;
        }
    }
}
.table-custom{
    .rdt_TableHeadRow{
        background-color: #FFF!important;
        color: #1A1919 !important;
        border: 0px!important;
        border-bottom: 1px solid#E0E7ED!important;
        &:hover {
            color: #1A1919 !important;
        }
    }
    .rdt_TableRow{
        height: 53px!important;
        border-bottom: 1px solid#E0E7ED!important;
    }
    .rdt_TableCol_Sortable {
        color: #1A1919 !important;
    }
    .rdt_TableHeadRow .disabled{
            background-color: #0d6efd!important;
            color: #1A1919 !important;
            border: 0px!important;
            border-bottom: 1px solid#E0E7ED!important;
            &:hover {
                color: #1A1919 !important;
            }
    }
}

.table-result-import{
    .rdt_Table{
        max-height: 750px;
        overflow: scroll;
    }
}
.no-record{
    font-weight: 600;
    color: #6C757D;
}
.badge-fbo{
    width: 50px;
    height: 25px;
    padding: 4px;
    display: inline-block;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.5px;
    border-radius: 8px;
    text-align: center;
    background-color: #daf2e7;
    color: #18AB56;
}
.badge-custom{
    height: 31px;
    padding: 8px 12px;
    display: inline-block;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 15px;
    border-radius: 8px;
}
.badge-custom-success{
    background-color: #F0FFF8;
    color: #18AB56;
}
.badge-custom-danger{
    background-color: #F8D7DA;
    color: #DC3545;
}
.badge-custom-grey{
    background-color: #F4F4F4;
    color: #878580;
}
.badge-custom-yellow{
    background-color: #FFE5D0;
    color: #FD7E14;
}
.badge-custom-primary{
    background-color: #CFE2FF;
    color: #0D6EFD;
}
.badge-custom-purple{
    background-color: #E0CFFC;
    color: #0d6efd;
}

// fleet badges
.badge-custom-all{
    background-color: #F4F4F4;
    color: #878580;
    &:hover {
        opacity: 0.8;
        background-color: #F4F4F4;
        color: #878580;
    }
}
.badge-custom-waiting-estimation{
    background-color: #FFFFFF;
    color: #454443;
    border: 2px solid #454443;
    &:hover {
        opacity: 0.8;
        background-color: #e6e6e4;
        color: #454443;
    }
}
.badge-custom-need-confirm{
    background-color: #96989a;
    color: #FFFFFF;
    &:hover {
        opacity: 0.8;
        background-color: #96989a;
        color: #FFFFFF;
    }
}
.badge-custom-revision{
    background-color: #0a0a0a;
    color: #FFFFFF;
    &:hover {
        opacity: 0.8;
        background-color: #0a0a0a;
        color: #FFFFFF;
    }
}
.badge-custom-waiting-service{
    background-color: #de5242;
    color: #FFFFFF;
    &:hover {
        opacity: 0.8;
        background-color: #de5242;
        color: #FFFFFF;
    }
}
.badge-custom-on-process{
    background-color: #f6c443;
    color: #FFFFFF;
    &:hover {
        opacity: 0.8;
        background-color: #f6c443;
    color: #FFFFFF;
    }
}
.badge-custom-order-done{
    background-color: #54b471;
    color: #FFFFFF;
    &:hover {
        opacity: 0.8;
        background-color: #54b471;
    color: #FFFFFF;
    }
}
.badge-custom-service-done{
    background-color: #fd7e14;
    color: #FFFFFF;
    &:hover {
        opacity: 0.8;
        background-color: #fd7e14;
    color: #FFFFFF;
    }
}
.badge-custom-cancel{
    background-color: #f7d5d8;
    color: #dd404f;
    &:hover {
        opacity: 0.8;
        background-color: #f7d5d8;
    color: #dd404f;
    }
}

.btn-no-border {
    border: none;
}

.badge-custom-all-filtered{
    background-color: #F4F4F4 !important;
    color: #878580 !important;
    filter: brightness(60%);
}
.badge-custom-waiting-estimation-filtered{
    background-color: #e6e6e4 !important;
    color: #454443 !important;
    filter: brightness(60%);
}
.badge-custom-need-confirm-filtered{
    background-color: #454443 !important;
    color: #FFFFFF !important;
    filter: brightness(60%);
}
.badge-custom-revision-filtered{
    background-color: #d9a513 !important;
    color: #FFFFFF !important;
    filter: brightness(60%);
}
.badge-custom-waiting-service-filtered{
    background-color: #08caf0!important;
    color: #FFFFFF!important;
    filter: brightness(60%);
}
.badge-custom-on-process-filtered{
    background-color: rgba(102, 16, 242, 1)!important;
    color: #FFFFFF!important;
    filter: brightness(60%);
}
.badge-custom-order-done-filtered{
    background-color: #16a162!important;
    color: #FFFFFF!important;
    filter: brightness(60%);
}
.badge-custom-service-done-filtered{
    background-color: #fd7e14!important;
    color: #FFFFFF!important;
    filter: brightness(60%);
}
.badge-custom-cancel-filtered{
    background-color: #f7d5d8!important;
    color: #dd404f!important;
    filter: brightness(60%);
}
.btn-filtered {
    filter: brightness(60%);
}

.status-filter {
    display: flex;
    justify-content: center;
}

.btn-filter {
    background-color: #FFFFFF !important;
    &:hover {
        background-color:  rgba(224, 207, 252, 1) !important;
    }
}

.btn-outline {
    padding: 8px 14px;
    color: #ACABA8;
    border-color: #ACABA8;
}

.box-payment {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #E6E6E4;
    width: calc(100% - 24px);
    margin: 0 auto;

}
//

.input-field{
    height: 38px;
    color: #052C65!important;
    background-color: #FFFFFF!important;
    border: 1px solid #CED4DA;
    &:focus{
        border: 1px solid #0d6efd!important;
    }
    &:disabled {
        background-color: #DEE2E6!important;
    }
}

.input-field-mask{
    border-radius: 5px;
    height: 38px;
    color: #052C65!important;
    background-color: #FFFFFF!important;
    border: 1px solid #CED4DA;
    &:focus{
        border: 1px solid #0d6efd!important;
    }
    &:disabled {
        background-color: #DEE2E6!important;
    }
    text-align: center;
}

.section-pagination{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 0.5rem 0;
    .section-row-per-page{
        width: 25%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .section-info-paging{
        width: 25%;
        text-align: right;
    }
    .section-paging{
        width: 50%;
        text-align: right;
    }
    .custom-pagination {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        .disabled {
            display: none;
        }
        li {
          float: left;
          cursor: pointer;
          padding: 6px 0;
          a {
            margin: 0 8px;
            border: unset;
            font-size: 14px;
            color: $soft-gray;
            padding: 8px 8px;
            color: #333333;
            background-color: #F8F9FA;
            border-radius: 8px;
            border-color: unset;
            border: unset;
            font-weight: 400;
            &:hover {
                color: $primary;
                background-color: $soft-purple;
            }
          }
          &.selected {
            a {
              color: #FFFFFF;
              background-color: #0d6efd;
            }
          }
        }
        .next-section, .prev-section{
            background-color: #F8F9FA;
            border-radius: 8px;
            a {
                margin: unset;
                padding: 25px;
                background-image: url('../assets/icons/right-arrow-paginate.svg');
                background-repeat: no-repeat;
                background-position: center;
                &:hover {
                    color: $primary;
                    background-color: $soft-purple;
                }
            }
        }
        .next-section{
            a {
                background-image: url('../assets/icons/right-arrow-paginate.svg');
            }
        }
        .prev-section{
            a {
                background-image: url('../assets/icons/left-arrow-paginate.svg');
            }
        }
      }
}

.input-field::-webkit-input-placeholder, .text-field-filter::-webkit-input-placeholder, .input-range-price::-webkit-input-placeholder, .input-select-text-field-filter::-webkit-input-placeholder,.input-text-select-field-filter::-webkit-input-placeholder, .date-field-filter::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #BDBDBD;
    font-weight: 200!important;
}
.input-field:-moz-placeholder, .text-field-filter:-moz-placeholder, .input-range-price:-moz-placeholder, .input-select-text-field-filter:-moz-placeholder, .input-text-select-field-filter::-moz-placeholder, .date-field-filter:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #BDBDBD;
    opacity: 1;
    font-weight: 200!important;
}
.input-field::-moz-placeholder, .text-field-filter::-moz-placeholder, .input-range-price::-moz-placeholder, .input-select-text-field-filter::-moz-placeholder, .input-text-select-field-filter::-moz-placeholder, .date-field-filter::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #BDBDBD;
    opacity: 1;
    font-weight: 200!important;
}
.input-field:-ms-input-placeholder, .text-field-filter:-ms-input-placeholder, .input-range-price:-ms-input-placeholder, .input-select-text-field-filter:-ms-input-placeholder, .input-text-select-field-filter:-ms-input-placeholder, .date-field-filter:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #BDBDBD;
    font-weight: 200!important;
}
.was-validated .input-field:invalid, .input-field.is-invalid {
    border-color: #ef4d56!important;
}
.was-validated .input-field:invalid, .input-field.is-invalid:focus {
    border-color: #ef4d56!important;
}
.w-p-35{
    width: 35%!important;
}
.w-p-65{
    width: 65%!important;
}
.w-p-100{
    width: 100%!important;
}
.w-63{
    width: 63px!important;
}
.w-120{
    width: 120px!important;
}
.w-170{
    width: 170px!important;
}
.w-200{
    width: 200px!important;
}
.w-230{
    width: 230px!important;
}
.w-250{
    width: 250px!important;
}
.w-261{
    width: 261px!important;
}
.w-374{
    width: 374px!important;
}
.w-390{
    width: 390px!important;
}
.w-400{
    width: 400px!important;
}
.w-425{
    width: 425px!important;
}
.w-500{
    width: 500px!important;
}
.w-600{
    width: 600px!important;
}
.w-700{
    width: 700px!important;
}
.w-800{
    width: 800px!important;
}

.text-link{
    cursor: pointer;
    text-decoration: underline;
    color: rgb(102, 16, 242);
    padding-top: 9,
}

.box {
    margin-top: 0;
    margin-bottom: 20px;
    position: relative;

    .icon-delete{
        object-fit: unset!important;
        position: absolute!important;
        margin-left: 25px;
        cursor: pointer;
        width: 30px!important;
        height: 25px!important;
        top: 5px;
        left: 5px;
        border-radius: 2px;
        border: 1px solid red;
        background-color: white;
        background-image: url('../assets/icons/trash.svg');
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: center center;
    }
}

.icon-new-tab{
    &:hover{
        cursor: pointer;
        // opacity: 0.8 !important;
        transform: scale(1.05);
    }
}

.icon-zoom{
    &:hover{
        filter: contrast(2%);
        cursor: pointer;
        // opacity: 0.8 !important;
        transform: scale(1.1);
    }
}

.zoom-icon{
    &:hover{
        cursor: pointer;
        opacity: 0.7 !important;
        transform: scale(1.1);
    }
}

.show-icon{
    transform: scale(1.5);
    &:hover{
        cursor: pointer;
        opacity: 0.7 !important;
        transform: scale(1.7);
    }
}

.disabled-icon {
    opacity: 0.5;
    &:hover{
        transform: scale(1);
    }
}

.input-date{
    .react-datepicker-wrapper{
        .date-field-input{
            width: 100%;
            height: 30px;
            border: 1px solid #E0E7ED;
            background-color: #FFFFFF;
            border-radius: 5px;
            background-image: url('../assets/icons/calender.svg');
            background-repeat: no-repeat;
            background-position: 200px 5px;
            padding: 8px 8px 8px 8px;
            color: #052C65!important;
            font-weight: 500!important;
            &:hover{
                cursor: pointer;
            }
            &:focus, &.focus, &.filled{
                border: 1px solid #0d6efd!important;
                background-image: url('../assets/icons/calender-purple.svg');
            }
            &:disabled{
                background-color: #FFFFFF!important;
                cursor: unset;
            }
            
        }
    }
    .react-datepicker__close-icon{
        &::after{
            background-color: #0d6efd;
        }
    }
    .react-datepicker__tab-loop{
        position: absolute;
    }
    .react-datepicker-popper{
        padding-top: 7px;
        .react-datepicker{
            padding-top: 15px;
            background-color: #FFFFFF;
            border: 0.5px solid #0d6efd;
            box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);
            border-radius: 6px;
        }
        .react-datepicker__triangle{
            &::after, &::before{
                border: unset;
                content: unset;
            }
        }
        .react-datepicker__navigation{
            top: 14px;
        }
        .react-datepicker__navigation.react-datepicker__navigation--previous{
            left: 0px;
        }
        .react-datepicker__navigation.react-datepicker__navigation--next{
            right: 0px;
        }
        .react-datepicker__month-container{
            padding: 0px 10px 0px 10px
        }
        .react-datepicker__header{
            background-color: #FFFFFF;
            border-bottom: unset;
            padding: 0px;
            .react-datepicker__current-month{
                margin: 0px 10px;
                font-size: 14px;
                font-family: sans-serif;
                letter-spacing: 0.5px;
                color: #212529;
            }
            .react-datepicker__day-names{
                margin-bottom: 5px;
            }
            .react-datepicker__day-name{
                font-weight: 600;
            }
            .react-datepicker__header__dropdown--select{
                padding-bottom: 35px;
                border-bottom: 1px solid #DEE2E6;
                margin-top: 10px;
            }
            .react-datepicker__month-dropdown-container--select{
                position: absolute;
                left: 0;
            }
            .react-datepicker__year-dropdown-container--select{
                position: absolute;
                right: 0;
            }
            .react-datepicker__month-select, .react-datepicker__year-select{
                border: unset;
                color: #0d6efd;
                font-weight: 600;
                border: 1px solid #0d6efd;
                height: 25px;
                border-radius: 6px;
                cursor: pointer;
            }
            .select-items{
                display: block;
            }
            .select-hide {
                display: none!important;
            }
        }
        .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
            font-family: sans-serif;
            color: #212529;
            font-weight: 500;
            margin: 2px 0px;
            width: 2rem;
            line-height: 2.5rem;
        }
        .react-datepicker__month{
            margin: 0rem 0.4rem 0.4rem 0.4rem;
            .react-datepicker__week{
                .react-datepicker__day{
                    &:hover{
                        color: #212529;
                        background-color: #E0CFFC;
                    }
                }
                .react-datepicker__day--today{
                    font-weight: 700;
                }
                .react-datepicker__day.react-datepicker__day--keyboard-selected {
                    background: none;
                    color: black;
                }
                .react-datepicker__day.react-datepicker__day--selected {
                    border-radius: 6px;
                    color: #FFFFFF;
                    background-color: #0d6efd;
                    &:hover{
                        color: #FFFFFF;
                        background-color: #0d6efd;
                    }
                }
                .react-datepicker__day.react-datepicker__day--in-range, .react-datepicker__day.react-datepicker__day--in-selecting-range{
                    color: #212529;
                    background-color: #E0CFFC;
                    border-radius: 0px;
                }
                .react-datepicker__day.react-datepicker__day--selecting-range-start, .react-datepicker__day.react-datepicker__day--range-start, .react-datepicker__day.react-datepicker__day--range-end {
                    border-radius: 6px 0px 0px 6px;
                    color: #FFFFFF;
                    background-color: #0d6efd;
                    &:hover{
                        color: #FFFFFF;
                        background-color: #0d6efd;
                    }
                }
                .react-datepicker__day.react-datepicker__day--range-end {
                    border-radius: 0px 6px 6px 0px;
                }
                .react-datepicker__day.react-datepicker__day--range-start.react-datepicker__day--range-end {
                    border-radius: 6px;
                }

                //outside month
                .react-datepicker__day.react-datepicker__day--outside-month{
                    color: #CED4DA;
                    &:hover{
                        background-color: #CED4DA;
                        color: #212529
                    }
                }
                .react-datepicker__day.react-datepicker__day--in-selecting-range.react-datepicker__day--outside-month, .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--outside-month{
                    background-color: #CED4DA;
                    color: #212529;
                }
                .react-datepicker__day.react-datepicker__day--selecting-range-start.react-datepicker__day--outside-month, .react-datepicker__day.react-datepicker__day--range-start.react-datepicker__day--outside-month, .react-datepicker__day.react-datepicker__day--range-end.react-datepicker__day--outside-month {
                    color: #FFFFFF;
                    background-color: #838485;
                    &:hover{
                        color: #FFFFFF;
                        background-color: #838485;
                    }
                }
                //end of outside month
            }
        }
        .react-datepicker__today-button {
            background-color: unset;
            border-top: 1px solid #DEE2E6;
            font-weight: 400;
            font-size: 14px;
            color: #0d6efd;
            font-family: sans-serif;
            letter-spacing: 0.5px;
            margin: 0px 10px 0px 10px;
            padding: 5px 0px 8px 0px;
            &:hover{
                font-weight: 600;
            }
        }
    }
}
.custom-breadcrumb-header{
    .page-title-box{
        padding: 0px!important;
    } 
}

.custom-breadcrumb-item{
    cursor: pointer!important;
}

.text-decoration-underline {
    text-decoration: underline!important;
}

.select-others{
    margin: 2px;
    .value-select{
        color: #000000;
        font-size: 95%;
        padding: 3px;
    }
}

.select-more{
    width: 80px;
    background-color: #68717A;
    cursor: pointer;
    float: right;
    border-radius: 0rem 0rem 0.25rem 0.25rem;
    &:hover{
        background-color: #DEE2E6;
    }
    .content-select-more{
        color: #FFFFFF;
        font-size: 85%;
        padding: 1px;
        text-align: center;
        &:hover{
            color: #68717A ;
        }
    }
}

.center-line {
    color: #6C757D;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    margin: 1rem 0 2rem 0;
    font-size: 14px;
    // color: #0d6efd;
    
    .active {
        color: #0d6efd;
    }

    &:after {
        content: "";
        flex: 1;
        height: 2px;
        background-color: #6C757D;
        margin-left: 10px;
    }
}

.text-line {
    color: #6C757D;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    margin: 1rem 0 2rem 0;
    font-size: 14px;

    .active {
        color: #0d6efd;
    }
}

.divider-line {
    display: flex;
    align-items: center;
    margin: -0.5rem 0 1rem 0;
    flex: 1;
    height: 1px;
    background-color: #c6cdd3;
}

.input-field-date{
    .react-datepicker-wrapper{
        .date-field-input{
            width: 100%;
            height: 38px;
            border: 1px solid #CED4DA;
            border-radius: 4px;
            padding: 8px 8px 8px 8px;
            color: #052C65!important;
            font-weight: 250!important;
            &:hover{
                cursor: pointer;
            }
            &:focus, &.focus, &.filled{
                border: 1px solid #0d6efd!important;
            }
            &:disabled{
                background-color: #FFFFFF!important;
                cursor: unset;
            }
            
            &:invalid{
                border: 1px solid #ef4d56!important;
            }
            
        }
    }
    .react-datepicker__input-time-container {
        margin-left: -25px;
        width: max-content;
        .react-datepicker-time__caption {
            display: inline;
            color: transparent;
        }
    }
    .react-datepicker__close-icon{
        &::after{
            background-color: #CED4DA;
            position: absolute;
            right: 20px;
            top: 10px;
        }
    }
    .react-datepicker__tab-loop{
        position: absolute;
    }
    .react-datepicker-popper{
        padding-top: 5px;
        z-index: 1500;
        // inset: auto auto auto 8px!important;
        // transform: unset!important;
        .react-datepicker{
            padding-top: 15px;
            background-color: #FFFFFF;
            border: 0.5px solid #0d6efd;
            box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);
            border-radius: 6px;
        }
        .react-datepicker__triangle{
            &::after, &::before{
                border: unset;
                content: unset;
            }
        }
        .react-datepicker__navigation{
            top: 14px;
        }
        .react-datepicker__navigation.react-datepicker__navigation--previous{
            left: 0px;
        }
        .react-datepicker__navigation.react-datepicker__navigation--next{
            right: 0px;
        }
        .react-datepicker__month-container{
            padding: 0px 10px 0px 10px
        }
        .react-datepicker__header{
            background-color: #FFFFFF;
            border-bottom: unset;
            padding: 0px;
            .react-datepicker__current-month{
                margin: 0px 10px;
                font-size: 14px;
                font-family: sans-serif;
                letter-spacing: 0.5px;
                color: #212529;
            }
            .react-datepicker__day-names{
                margin-bottom: 5px;
            }
            .react-datepicker__day-name{
                font-weight: 600;
            }
            .react-datepicker__header__dropdown--select{
                padding-bottom: 35px;
                border-bottom: 1px solid #DEE2E6;
                margin-top: 10px;
            }
            .react-datepicker__month-dropdown-container--select{
                position: absolute;
                left: 0;
            }
            .react-datepicker__year-dropdown-container--select{
                position: absolute;
                right: 0;
            }
            .react-datepicker__month-select, .react-datepicker__year-select{
                border: unset;
                color: #0d6efd;
                font-weight: 600;
                border: 1px solid #0d6efd;
                height: 25px;
                border-radius: 6px;
                cursor: pointer;
            }
            .select-items{
                display: block;
            }
            .select-hide {
                display: none!important;
            }
        }
        .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
            font-family: sans-serif;
            color: #212529;
            font-weight: 500;
            margin: 2px 0px;
            width: 2rem;
            line-height: 2.5rem;
        }
        .react-datepicker__month{
            margin: 0rem 0.4rem 0.4rem 0.4rem;
            .react-datepicker__week{
                .react-datepicker__day{
                    &:hover{
                        color: #212529;
                        background-color: #E0CFFC;
                    }
                }
                .react-datepicker__day--today{
                    font-weight: 700;
                }
                .react-datepicker__day.react-datepicker__day--keyboard-selected {
                    background: none;
                    color: black;
                }
                .react-datepicker__day.react-datepicker__day--selected {
                    border-radius: 6px;
                    color: #FFFFFF;
                    background-color: #0d6efd;
                    &:hover{
                        color: #FFFFFF;
                        background-color: #0d6efd;
                    }
                }
                .react-datepicker__day.react-datepicker__day--in-range, .react-datepicker__day.react-datepicker__day--in-selecting-range{
                    color: #212529;
                    background-color: #E0CFFC;
                    border-radius: 0px;
                }
                .react-datepicker__day.react-datepicker__day--selecting-range-start, .react-datepicker__day.react-datepicker__day--range-start, .react-datepicker__day.react-datepicker__day--range-end {
                    border-radius: 6px 0px 0px 6px;
                    color: #FFFFFF;
                    background-color: #0d6efd;
                    &:hover{
                        color: #FFFFFF;
                        background-color: #0d6efd;
                    }
                }
                .react-datepicker__day.react-datepicker__day--range-end {
                    border-radius: 0px 6px 6px 0px;
                }
                .react-datepicker__day.react-datepicker__day--range-start.react-datepicker__day--range-end {
                    border-radius: 6px;
                }

                //outside month
                .react-datepicker__day.react-datepicker__day--outside-month{
                    color: #CED4DA;
                    &:hover{
                        background-color: #CED4DA;
                        color: #212529
                    }
                }
                .react-datepicker__day.react-datepicker__day--in-selecting-range.react-datepicker__day--outside-month, .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--outside-month{
                    background-color: #CED4DA;
                    color: #212529;
                }
                .react-datepicker__day.react-datepicker__day--selecting-range-start.react-datepicker__day--outside-month, .react-datepicker__day.react-datepicker__day--range-start.react-datepicker__day--outside-month, .react-datepicker__day.react-datepicker__day--range-end.react-datepicker__day--outside-month {
                    color: #FFFFFF;
                    background-color: #838485;
                    &:hover{
                        color: #FFFFFF;
                        background-color: #838485;
                    }
                }
                //end of outside month
            }
        }
        .react-datepicker__today-button {
            background-color: unset;
            border-top: 1px solid #c6cdd3;
            font-weight: 400;
            font-size: 14px;
            color: #0d6efd;
            font-family: sans-serif;
            letter-spacing: 0.5px;
            margin: 0px 13px 0px 13px;
            padding: 5px 0px 8px 0px;
            &:hover{
                font-weight: 600;
            }
        }
    }
}

.datetime-separator{
    display: flex;
    min-height: 100%;
    &::before{
        position: absolute;
        content: ':';
        left: -2px;
        top: 20%;
    }
}

.advance-area-icon{
    position: absolute;
    cursor: pointer;
    right: 16px;
    top: 8px;
}

.fx-start{
    align-items: flex-start!important;
}

.custom-divider {
    color: $light_gray;
    display: flex;
    align-items: center;
    margin: 1rem 0;

    &:after {
        content: "";
        flex: 1;
        height: 1px;
        background-color: $light_gray;
    }
}

.default-button{
    width: 100%;
    padding: 0.375rem 0rem;
    cursor: pointer; 
    background: #CFE2FF!important;
    border: 1px solid #0D6EFD!important;
    border-radius: 3px;
    color: #0D6EFD!important;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.466667px;
    box-shadow: none !important;
    // padding: 3px 4px!important;
    &:hover{
        opacity: 0.8!important;
    }
    &:disabled {
        opacity: 0.4;
        cursor: not-allowed!important;
    }
}

.modal-add-car {
    .modal-header {
        background-color: #fff;
        button {
            display: none;
        }
    }

    .modal-body {
        button {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            letter-spacing: 0.457143px;
            padding: 8px 10px;
        }
    }

    .modal-footer {
        button {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.457143px;
            padding: 9px 12px;
        }
    }
}

.car-tabs {
    .nav-link {
        background-color: #fff;
    }
}

.customer-car {
    table {
        .head {
            height: 50px;
            border-bottom: 1px solid;
            position: sticky;
            top: 0;
            background-color: #fff;
        }

        .car-img {
            width: 110px;
        }

        td {
            width: 90px;
        }
    }
}

.form-car-compability {
    .input-year {
        // width: 64.5px;
        margin: 0;
    }

    hr {
        width: 6px;
        border: 1px solid #ADB5BD;
    }
}

.filter-car {
    .section-filters {
        input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        .select-field-filter {
            div[class*="control"] {
                margin-left: -8px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }
}

.header-danger{
    color: #ef4d56!important;
}

.error-result-danger{
    color: #DC3545;
    font-weight: 500;
}

.inspection-badge {
    border-radius: 3px;
    padding: 10px 20px 10px 20px;
}

.badge-critical {
    background-color: #dc3446;
}
.badge-standby {
    background-color: #d9a513;
}
.badge-safe {
    background-color: #198754;
}

.booking-create-form-items-card {
    background: rgba(224, 207, 252, 0.2);
    border: 1px solid #0d6efd;
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 4px;
}

.dropdown-item {
    &:hover {
        background-color: #e9ecee !important;
    }
}

.cut-text { 
    text-overflow: ellipsis;
    overflow: hidden; 
    width: 200px; 
    white-space: nowrap;
}

.m-icon-approved { 
    margin-top: 2px;
    margin-left: 2px;
}

.m-icon-rejected { 
    margin-top: 2px;
}

.m-icon-list-rejected { 
    margin-top: 2px;
    margin-left: 2px;
    margin-right: 2px;
}

body {
    overflow: --moz-scrollbars-vertical;
    overflow-y: scroll; /* Show scrollbars */
}

.default-custom_chat_button {
    position: fixed;
    bottom: 15px;
    right: 15px;
    height: 60px;
    background: url('../assets/icons/OtoBuddy-Live.svg') no-repeat, white;
    width: 60px;
    // box-shadow: 0px 1px 12px rgb(207, 205, 210);
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    background-size: 100%;
    z-index: 300;
    transition: visibility 0s linear 0s, opacity 400ms;
    border-radius: 100%;
    border: 5px solid #fff;
    cursor: pointer;
}

.custom_fc_frame {
    bottom: 70px !important;
}

.dropdown-account {
    border-radius: 10px !important;
}

.overflow-title {
    -moz-hyphens:auto;
    -ms-hyphens:auto;
    -webkit-hyphens:auto;
    hyphens:auto;
    word-wrap:break-word;
 }

 .scroll-menu-wrapper {
    display: flex;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    width: 100%;
 }

 .package-info {
    width: 100%;
    padding: 12px 16px 12px 16px;
    border-radius: 8px;
    background-color: #F5F5F4;
    display: flex;
    align-items: center;

    img {
        margin-right: 8px;
    }
 }

.user-select-none {
    user-select: none;
}

// wrapper bottom fixed
.wrapper-fixed-bottom {
    // width: map-get($wrapper-breakpoints, 'xs');
    width: 100%;
    background-color: $white;
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.12);
  }
  
  @include respond-below('sm') {
    .wrapper-fixed-bottom {
      min-width: 100%;
      max-width: 100%;
    }
  }

  .skeleton-placeholder {
    background: #eaecef;
    width: 100%;
    height: 1.45rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    position: relative;
    overflow: hidden;
    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
          90deg,
          rgba(#fff, 0) 0,
          rgba(#fff, 0.2) 20%,
          rgba(#fff, 0.5) 60%,
          rgba(#fff, 0)
        );
        animation: shimmer 1s infinite;
        content: '';
    }
    @keyframes shimmer {
        100% {
          transform: translateX(100%);
        }
    }
  }

.border-table {
    border-radius: 8px;
    border: 1px solid #E6E6E4;
}

.no-data {
    text-align: center;
    img {
        margin-top: 20px;
    }
    h4 {
        margin-top: 20px;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
    }
    p {
        margin-top: 12px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #676765;
    }
}

.badge-status {
    .badge-secondary {
        border-radius: 4px !important;
        background-color: #D2DCF7 !important;
        color: #002EB4;
        font-weight: 500;
    }
    .badge-warning {
        border-radius: 4px !important;
        background-color: #FAF6CF !important;
        color: #B3870E;
        font-weight: 500;
    }
    .badge-success {
        border-radius: 4px !important;
        background-color: #aeefbd !important;
        color: #128a2d;
        font-weight: 500;
    }
    .badge-danger{
        border-radius: 4px !important;
        background-color: #f5c1c3 !important;
        color: #dc3545;
        font-weight: 500;
    }
    .badge-info{
        border-radius: 4px !important;
        background-color: #dff1f4 !important;
        color: #14a2b8;
        font-weight: 500;
    }
    .badge-dark{
        border-radius: 4px !important;
        background-color: #343a40 !important;
        color: #fff;
        font-weight: 500;
    }
}

.badge-settlement {
    .badge-secondary {
        border-radius: 4px !important;
        background-color: #002EB4 !important;
        color: #ffffff;
        font-weight: 500;
    }
    .badge-warning {
        border-radius: 4px !important;
        background-color: #B3870E !important;
        color: #ffffff;
        font-weight: 500;
    }
    .badge-success {
        border-radius: 4px !important;
        background-color: #16A163 !important;
        color: #ffffff;
        font-weight: 500;
    }
    .badge-danger{
        border-radius: 4px !important;
        background-color: #dc3545 !important;
        color: #ffffff;
        font-weight: 500;
    }
    .badge-info{
        border-radius: 4px !important;
        background-color: #14a2b8 !important;
        color: #ffffff;
        font-weight: 500;
    }
    .badge-dark{
        border-radius: 4px !important;
        background-color: #343a40 !important;
        color: #fff;
        font-weight: 500;
    }
}

.title-page {
    p {
        color: #676765;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 0 !important;
        margin-left: 12px;
    }
}

.icon-bg-danger {
    background-color: #FEE4E2;
    padding: 12px;
    border-radius: 50%;
    width: fit-content;
    margin: 0 auto;
    border: 8px solid #FEF3F2;
    animation: circle 3s infinite;
    clip-path: circle(75%);
}

.icon-bg-warning {
    background-color: #FEF0C7;
    padding: 12px;
    border-radius: 50%;
    width: fit-content;
    margin: 0 auto;
    border: 8px solid #FFFAEB;
    animation: circle 3s infinite;
    clip-path: circle(75%);
}

@keyframes circle {
    0% { clip-path: circle(75%); }
    50% { clip-path: circle(25%); }
    100% { clip-path: circle(75%); }
}

.div-alert {
    width: 100%;
    position: relative;
    text-align: center;
    justify-content: center;
    display: flex;
}

.alert-custom {
    position: fixed;
    z-index: 10000;
    border: 0;
    margin: 0 auto;
    width: fit-content;
    top: 20px;
    border-radius: 8px;
    text-align: center;
}

.alert-success {
    background-color: #16A163 !important;
    color: #fff;
    img {
        margin-right: 8px;
    }
}

.alert-failed {
    background-color: #D91F11 !important;
    color: #fff;
    img {
        margin-right: 8px;
    }
}

.alert-warning {
    background-color: #D91F11 !important;
    color: #fff;
    img {
        margin-right: 8px;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
//   -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
