.form-section {
    background-color: #F8F9FA;
    border-radius: 16px;
    padding: 20px 8px 2px;
}

//filter date
.date-picker-input {
    height: 35px;
    width: 170px;
}

// Operational Hours
.is-open-switch {
    .form-group {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        height: 100%;
    }
}

.input-hours {
    text-align: center;
}
.modal-add-video-workshop{
    .modal-content {
        background: #FCFCFC;
        border-radius: 4px!important;
        align-items: center;
        width: 460px;
        display: flex;
        flex-direction: column;
    }
    .modal-body {
        padding: unset!important;;
    }
    .body{
        background: #FCFCFC;
        border-radius: 8px!important;
        align-items: center;
        width: 460px;
        display: flex;
        flex-direction: column;
        .header{
            width: 460px;
            height: 44px;
            font-weight: 500;
            font-size: 15px;
            line-height: 44px;
            text-align: left;
            letter-spacing: 1px;
            color: #14142B;
            padding-left: 16px!important;
            padding-right: 16px!important;
            border-bottom: 2px solid #E9ECEF;
        }
        .button-close{
            background-image: url('../assets/icons/close.svg');
            background-position: center center;
            background-size: 10px;
            background-repeat: no-repeat;
            cursor: pointer;
            position: absolute;
            top: 18px;
            right: 18px;
            width: 8px!important;
            height: 8px!important;
        }
        .content{
            width: 460px;
            font-weight: 400!important;
            font-size: 12px!important;
            letter-spacing: 1px!important;
            color: #68717A!important;
            text-align: left;
            padding-left: 16px!important;
            padding-right: 16px!important;
            padding-top: 13px!important;
        }
        .action{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0px;
            gap: 40px;
            width: 374px;
            height: 40px;
            margin-top: 5px;
            .button-action{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 12px 16px;
                width: 206px;
                height: 40px;
                color: #FFFFFF;
                background: rgba(13, 110, 253, 1);
                border-radius: 8px;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 1px;
            }
            .button-cancel{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 12px 16px;
                width: 206px;
                height: 40px;
                color: #0D6EFD;
                background: rgba(128, 189, 255, 0.5);
                border-radius: 8px;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 1px;
            }
            .button-action:hover{
                background: rgba(13, 110, 253, 0.8);
            }
            .button-cancel:hover{
                background: rgba(128, 189, 255, 0.4);
            }
        }
    }
}

.text-left{
    text-align: left!important;
}

.text-bold{
    font-weight: 700;
}
.facility-icon{
    margin-top: -3px;
    width: 14px;
    height: 15px;
}
.facility-input{
    margin-left: 0px!important;
    background-color: red;
}
.facility-text{
    letter-spacing: 1px;
    color: #6E7191;
}
.facility-text-disabled{
    text-decoration: line-through;
    text-decoration-color: #656d9a;
    opacity: 0.8;
}
.facility-disabled{
    opacity: 0.4;
}
.document-workshop{
    color: #0D6EFD;
    cursor: pointer;
}

.document-box-workshop{
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    border: 2px dashed #DEE2E6;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 5px;
    .file{
        width: 100%;
        cursor: pointer;
        color: #0D6EFD;
        display: inline-block;
        font-weight: 500!important;
    }
    .choose-file{
        color: #6C757D;
        display: inline-block;
        font-weight: 500!important;
    }
    .icon-trash-workshop{
        cursor: pointer;
        width: unset!important;
        height: 15px!important; 
    }
}

.drag-over{
    border: 2px dashed #0D6EFD!important;
}

.document-guide{
    width: 100%;
    color: #0D6EFD;
    font-weight: 400!important;
}

.invalid-document{
    width: 100%;
    margin-top: 0.25rem;
    font-size: 94%;
    color: #ef4d56;
    font-weight: normal!important;
}

.invalid-hour{
    width: 100%;
    margin-top: 0.25rem;
    font-size: 94%;
    color: #ef4d56;
    font-weight: normal!important;
}

.rating-icon{
    margin-top: -2px !important;
    margin-left: 0.25rem !important
}
.rating{
    color: #FF6400;
    line-height: 18px;
}
.review{
    color: #A0A3BD;
    line-height: 18px;
    font-weight: 400!important;
}

.col-radio-workshop input[type="radio"] {
	width: 15px;
	height: 15px;
	border: 1px solid #0D6EFD;
	border-radius: 50%;
	padding: 2px;
}

.col-radio-workshop input[type="radio"]:checked {
    // background-color: #0D6EFD;
    background-color: #FFF;
    border: 4px solid #0D6EFD;
}

.col-radio-workshop input[type="radio"]:disabled {
    border: 1px solid #DEE2E6!important;
}

.col-radio-workshop input[type="radio"]:checked:disabled {
    background-color: #FFF!important;
    border: 4px solid #DEE2E6!important;
}

.radio-workshop{
    position: unset!important;
    margin-top: 0.5rem!important;
    margin-left: unset!important;
}

.label-radio{
    position: relative;
    top: -3px;
}

.product-price{
    color: #0D6EFD;
    font-weight: 700;
    letter-spacing: 0.5px;
}
.gallery-container-workshop {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-column-gap: 20px;
    width: 100%;
    margin-top: 10px;
  
    .box {
      overflow: hidden;
      margin-top: 0;
      margin-bottom: 20px;
      position: relative;
      width: 209px;    
  
      img {
        object-fit: cover;
        width: 209px;    
        height: 149px;
        border: 1px solid #ADB5BD;
        border-radius: 2px;
      }

      h1 {
        font-size: 13px;
        font-weight: 700;
        margin: 0;
        line-height: 25px;
        margin-bottom: 10px;
        margin-top: 10px;
      }
    
      h2 {
        font-size: 13px;
        font-weight: 400;
        margin: 0;
        line-height: 20px;     
        overflow: hidden;
      }

      .all-scroll{
        cursor: all-scroll;
      }

      .icon-trash{
        object-fit: unset!important;
        position: absolute!important;
        cursor: pointer;
        width: 30px!important;
        height: 25px!important;
        top: 5px;
        left: 5px;
        border-radius: 2px;
        border: 1px solid red;
        background-color: white;
        background-image: url('../assets/icons/trash.svg');
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: center center;
    }
      
      .container-cx-round {
        position: absolute;
        left: 5px;
        top: 5px;
  
        .round {
          position: relative;
        }
  
        .round input[type="checkbox"] {
          width: 24px;
          height: 24px;
          background: #FFFFFF;
          border: 1px solid #D6D8E7;
          border-radius: 8px!important;
        }
      }
    }

    .box-upload {
        text-align: center;
        background: #F8F9FA;
        border: 1px solid #ADB5BD;
        border-radius: 2px;
        overflow: hidden;
        position: relative;
        width: 209px;    
        height: 149px;
        padding: 25px;
        cursor: pointer;
        .title{
            font-weight: 400;
            font-size: 12px;
            color: #212529;
        }
        .detail{
            font-weight: 400;
            font-size: 10px;
            color: #6C757D;
        }
    }
  }

  .box.nothing-image {
    img {
        object-fit: cover;
        width: 209px!important;    
        height: 149px!important;
        border-radius: 2px;
        border: 3px dashed #0d6efd!important;
    }
  }

  .box.nothing-videos {
    img {
        object-fit: cover;
        width: 209px!important;    
        height: 149px!important;
        border-radius: 2px;
        border: 3px dashed #0d6efd!important;
    }
  
    h1 {
      font-size: 13px;
      font-weight: 700;
      margin: 0;
      line-height: 25px;
      margin-bottom: 10px;
      margin-top: 10px;
    }
  
    h2 {
      font-size: 13px;
      font-weight: 400;
      margin: 0;
      line-height: 20px;     
      overflow: hidden;
    }
  }
//disabled div
div[disabled]
{
  pointer-events: none;
  opacity: 0.7;
}

.delete-item {
    position: absolute !important; 
    cursor: pointer;
    top: 6px;
    left: 0px;
}