.story-wrapper {
  position: relative;
  margin: 1rem -.75rem 0;
  border: solid #dee2e6;
  padding: 1.5rem;
  margin-right: 0;
  margin-left: 0;
  border-width: 1px;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.text-xxs {
  font-size: $otoklix-font-size-xxs !important;
}

.text-xs {
  font-size: $otoklix-font-size-xs;
}

.text-sm {
  font-size: $otoklix-font-size-sm;
}

.text-md {
  font-size: $otoklix-font-size-md;
}

.text-lg {
  font-size: $otoklix-font-size-lg;
}

.text-underline {
  text-decoration: underline;
}

.text-strikethrough {
  text-decoration: line-through;
}

.border-none {
  border: none;
}