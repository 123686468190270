.empty-state-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;

  .image {
    margin-bottom: 1rem;
  }

  .title {
    font-weight: 700;
    font-size: $otoklix-font-size;
    text-align: center;
  }

  .caption {
    margin-top: 1rem;
    font-size: $otoklix-font-size-md;
    text-align: center;
  }

  .footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;

    .button {
      font-size: $otoklix-font-size-xs;

      &:nth-child(2) {
        margin-right: 0.5rem;
      }
    }
  }
}
