.payments {
  .head {
    span {
      font-weight: 700;
      font-size: px2rem(20);
      color: #1A1919;
    }
  }
  .cart {
    border: 1.3px solid #e6e6e4;
    .promo-body {
        background-color: #002EB4;
        padding: 1rem 1rem 0.4rem 1rem;
        margin-top: -10px;
        border-radius: 0 0 0.5rem 0.5rem;
        z-index: 9;
        .promo-col {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            cursor: pointer;
        }
        .icon-plus {
            border: 1.5px solid #FCFCFC;
            border-radius: 50%;
            padding: 1px 6px;
            color: #FCFCFC;
            font-weight: 600;
        }
        .icon-close {
            border: 1.5px solid #FCFCFC;
            border-radius: 50%;
            padding: 0px 6px;
            color: #FCFCFC;
            font-weight: 600;
        }
        .text-promo {
            color: #FCFCFC;
            display: flex;
            align-items: center;
            margin-left: 8px;
        }
        .nominal-promo {
            color: #FCFCFC;
            display: flex;
            align-items: center;
        }
    }
  }
  .divider-line {
    color: $light_gray;
    display: flex;
    align-items: center;
    &:after {
        content: "";
        flex: 1;
        height: 1px;
        background-color: #e6e6e4;
        margin-left: 0;
    }
  }
  .dashed-line {
      border-top: 1.2px dashed rgba(137, 137, 135, 1);
      background-color: #fff;
  }
  .text-title-grey {
    color: rgba(137, 137, 135, 1);
  }
  .text-subtitle-grey {
      color: rgba(69, 68, 67, 1);
      font-weight: 600;
  }
  .text-default-blue {
      color: rgba(0, 46, 180, 1);
  }
  .input-promo {
      .clear-icon-box {
          display: block;
          .clear-icon {
              position: absolute;
              margin: 5px 15px 0px 0px;
              top: 0;
              right: 0;
          }
      }
  }
  .select-payment {
      width: 96%;
      height: 44px!important;
      border: 1.3px solid #e6e6e4!important;
      cursor: pointer;
      border-radius: 8px;
      display: flex;
      margin: 0px 10px 0px 10px;
      .text-field {
          padding: 10px 0px 10px 0px;
          width: 88%;
          overflow: auto;
          white-space: nowrap;
      }
      .text-field::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
      .icon-field-box {
          display: block;
          .icon-field {
              position: absolute;
              margin: 60px 25px 0px 0px;
              top: 0;
              right: 0;
          }
      }
      &.invalid {
          border: 1.3px solid rgba(217, 31, 17, 1)!important;
      }
  }
  .select-field-payment{
      width: 100%;
      height: 44px!important;
      border-radius: 8px;
      border: unset!important;
      background-color: #FFFFFF;
      border: 1.3px solid #e6e6e4!important;
      background-image: url('../assets/icons/select-icon.svg');
      background-repeat: no-repeat;
      background-position: bottom 15px right 15px;
      padding: 8px 45px 10px 15px;
      font-weight: 400!important;
      color: rgba(69, 68, 67, 1) !important;
      &.disabled {
          background-color: #FFFFFF !important;
          color: rgba(69, 68, 67, 1) !important;
      }
  }
  .button-blue{
      height: 40px;
      padding: 10px 24px 10px 24px;
      font-family: 'poppins';
      border-radius: 24px;
      text-align: center;
      letter-spacing: 0.466667px;
      box-shadow: none !important;
      border: none;
      font-weight: 600!important;
      background-color: #002EB4;
      &:disabled {
          opacity: 0.4;
          cursor: not-allowed!important;
      }
      &:hover {
          background-color: #002EB4;
      }
      &.outline {
          background-color: #fff;
          border: 1.3px solid #002EB4;
          color: #002EB4;
          &:focus, &:active {
              background-color: #fff;
              border: 1.3px solid #002EB4;
              color: #002EB4;
          }
      }
      &:focus, &:active {
          background-color: #002EB4;
      }
  }
  .custom-switch.switch-otopoints
  .custom-control-input:checked ~ 
  .custom-control-label::before {
      color:rgba(206, 205, 202, 1);
      border-color:#002EB4;
      background-color:#002EB4
  }
  .custom-switch.switch-otopoints
  .custom-control-input:not(:checked) ~ 
  .custom-control-label::after { 
      border-color: #FFFF !important;
      background-color: #FFFF !important;
  }
  .custom-switch.switch-otopoints
  .custom-control-input:not(:checked) ~ 
  .custom-control-label::before { 
      border-color: rgba(206, 205, 202, 1) !important;
      background-color: rgba(206, 205, 202, 1) !important;
  }
  .custom-switch.switch-otopoints
  .custom-control-input:focus ~ 
  .custom-control-label::before {
      -webkit-box-shadow:0 0 0 0.11px rgba(253,163,84,0.25);
      box-shadow:0 0 0 0.11px rgba(253,163,84,0.25)
  }
  .custom-switch.switch-otopoints
  .custom-control-input:focus:not(:checked) ~ 
  .custom-control-label::before { 
      // border-color:rgba(137,151,189,0.7);
      border-color: rgba(206, 205, 202, 1) !important;
      background-color: rgba(206, 205, 202, 1) !important;
  }
  .custom-switch.switch-otopoints
  .custom-control-input:focus:not(:checked) ~
  .custom-control-label::after { 
      border-color: #FFFF !important;
      background-color: #FFFF !important;
  }
  .gallery-container-item {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(100px, 80px));
      grid-column-gap: 10px;
      width: 100%;
    
      .box {
        overflow: hidden;
        position: relative;
        margin: 0px;   
    
        img {
          object-fit: cover;
          width: 90px;    
          height: 90px;
          border: none;
          border-radius: 6px;
        }
      }
  }
  .input-group-text-left {
      font-size: .75rem;
      background-color: rgba(245, 245, 244, 1);
      border: 1.3px solid rgba(222, 222, 220, 1);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-right: 0px solid;
      font-weight: 600;
      padding: 10px 15px;
    }
  .input-custom{
      height: 44px;
      color: #052C65!important;
      background-color: #FFFFFF!important;
      border: 1.3px solid rgba(222, 222, 220, 1);
      border-radius: 8px;
      font-size: 14px;
      padding: 18px 35px 18px 15px;
      &::placeholder {
          color: rgba(172, 171, 168, 1) !important;
        }
      &.with-prefix {
          border-top-right-radius: 8px !important;
          border-bottom-right-radius: 8px !important;
        }
      &:focus{
          border: 1px solid #002EB4!important;
      }
      &:disabled {
          background-color: rgba(245, 245, 244, 1)!important;
      }
      &.valid {
          border: 1.3px solid rgba(22, 161, 99, 1);
      }
      &.invalid {
          border: 1.3px solid rgba(217, 31, 17, 1);
      }
  }
  .text-green {
      color: rgba(22, 161, 99, 1);
    }
  .text-bold-600{
      font-weight: 600;
  }
  .w-p-100{
      width: 100%!important;
  }

  .skeleton-placeholder {
    margin: 10px 0 0 0;
  }
}

.button-select{
  width: 100%;
  height: 40px;
  padding: 10px 10px;
  font-family: 'poppins';
  border-radius: 8px;
  text-align: center;
  letter-spacing: 0.466667px;
  box-shadow: none !important;
  border: 1.5px solid rgba(230, 230, 228, 1);
  color: rgba(69, 68, 67, 1);
  font-weight: 400!important;
  background-color: #ffff;
  text-decoration: none;
  &:disabled {
      opacity: 0.4;
      cursor: not-allowed!important;
  }
  &:hover, &:active, &:focus {
      background-color: rgba(210, 220, 247, 1);
      border: 1.5px solid #002EB4;
      color: #002EB4;
  }
  &.outline {
      background-color: rgba(210, 220, 247, 1);
      border: 1.5px solid #002EB4;
      color: #002EB4;
  }
}

.modal-dialog-centered {
    &.modal-payment{
      font-family: 'poppins';
      .modal-title {
        color: rgba(26, 25, 25, 1) !important;
        font-weight: 600 !important;
        font-size: 16px;
        border-bottom: 1px solid rgba(230, 230, 228, 1);
        padding-bottom: 10px;
        display: flex;
        .close-icon-box {
          display: block;
          .close-icon {
            position: absolute;
            margin: 60px 30px;
            top: 20px;
            right: 15px;
            height: 12px;
          }
        }
      }
      .modal-content {
          height: min-content;
          background: #FFFF!important;
          border-radius: 8px!important;
          border: unset!important;
      }
      .modal-body {
          padding: 20px 13px!important;
          letter-spacing: 1px;
      }
    }
}

.promo-card {
    :hover {
        background-color: rgba(222, 222, 220, 1);
    }
}

.promo-icon-list {
    border-radius: 8px;
    height: 43px;
    border: 1.3px solid rgba(222, 222, 220, 1);
    padding: 7px;
    margin: 1px 0px 0 0;
}

.tnc-field {
  p {
    color: rgba(103, 103, 101, 1) !important;
  }
  a {
    color: #002EB4 !important;
  }
  /* width */
  &::-webkit-scrollbar {
    width: 8px;
    border: none;
    background: transparent;
  }
  
  /* Track */
  &::-webkit-scrollbar-track {
    background: lightgrey;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    background-clip: padding-box;
  }
   
  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #002EB4;
  }
}

.promo-field {
    /* width */
    &::-webkit-scrollbar {
      width: 8px;
      border: none;
      background: transparent;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
      background: lightgrey;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      background-clip: padding-box;
    }
     
    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-color: #002EB4;
    }
  }