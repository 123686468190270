.modal-dialog.modal-bottom {
  margin: 0;
}

.modal-dialog {
  margin: auto;
}

.modal-content {
  height: 100vh;
}

.modal-content.square { 
  border-radius: 0px;
}

.modal-content.mobile {
  width: 362px;
  min-width: 362px;
  margin: 0 auto;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -1px);
}

.modal.in .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -1px);
}

.modal.out .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, 100vh);
}

@include respond-below("sm") {
  .modal-content.mobile {
    width: 100%;
  }
}
