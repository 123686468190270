.search-input {
  display: flex;

  img {
    background-color: #fff;
    border-radius: 4px 0 0 4px;
    border: 1px solid #cccccc;
    border-right: none;
    padding-left: 8px;
  }

  input {
    border-left: none;
    border-radius: 0 4px 4px 0;
    border-color: #cccccc;
  }
}

#search-input:focus {
  border-color: #cccccc !important;
}

.dropdown-list {
  background-color: #fff;
  border: 1px solid #cccccc;
  font-size: 10;
  padding: 8px 4px;
  display: none;
  flex-direction: column;
  position: absolute;
  top: 42px;
  z-index: 2;
  border-radius: 4px;
  max-height: 200px;
  overflow: auto;
  
  span.placeholder {
    padding: 4px;
    cursor: default;
  }

  span.option-list {
    padding: 4px;

    &:hover {
      background-color: #b3d2ff;
      color: #0d6efd;
      cursor: pointer;
      border-radius: 4px;
    }
  }
}
