.appbar {
  height: $appbar-height;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  width: map-get($wrapper-breakpoints, "xs");
  margin: 0 auto;
  background-color: $offwhite;
  box-shadow: 0 -1px 10px 0 rgb(198, 200, 220);

  .tabs {
    display: flex;
    flex-direction: row;
    height: 100%;
    border-top: 1px solid $black-xs;

    .tab {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;

      span {
        font-size: px2rem(12);
        color: $placeholder;
        font-weight: $font-weight-medium;
      }

      &.fab {
        margin-top: px2rem(-13);
        background: transparent;

        img {
          margin-bottom: px2rem(12px);
        }
      }

      &.is-active {
        span {
          font-weight: $font-weight-bold;
          color: $primary;
        }
      }
    }
  }
}

@include respond-below("sm") {
  .appbar {
    width: 100%;
  }
}
