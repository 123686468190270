.header {
  padding: 1rem;
}

.header-container {
  margin: 0;
  position: relative;
  
  .right-image {
    position: absolute;
    right: 0;
    cursor: pointer;
  }
}

.back-page {
  left: 0;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
  vertical-align: baseline;
  cursor: pointer;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNyAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuMjUgNy4yNzQ0MUwxNi4yNSA3LjI3NDQxIiBzdHJva2U9IiM4OTg5ODciIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTcuMjk5OCAxMy4yOTg4TDEuMjQ5OCA3LjI3NDc2TDcuMjk5OCAxLjI0OTc2IiBzdHJva2U9IiM4OTg5ODciIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
  position: relative;
  width: 48px;
  height: 48px;
  margin-right: 1rem;
}

.header-text {
  color: $title-active;
  text-align: left;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0;
  max-width: 80%;
}
