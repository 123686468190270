.badge-lg {
  border-radius: $border-radius;
}

.badge-md {
  border-radius: $border-radius;
}

.badge-sm {
  padding: $badge-padding-y $badge-padding-x;
  border-radius: $border-radius-sm;
  font-size: $otoklix-font-size-xxs;
  font-weight: 400;
  line-height: 1;
}

.tags {
  display: inline-block;
  border-radius: $border-radius-sm;
  padding: px2rem(8) px2rem(16);
  align-items: center;
  letter-spacing: 1px;
  font-weight: $font-weight-normal;
  font-size: $otoklix-font-size-xs;
  line-height: 18px;
  white-space: nowrap;

  img {
    margin-right: 0.5rem;
  }

  &.lg {
    font-size: 1rem;
    line-height: 24px;
    border-radius: $border-radius;
  }

  &.sm {
    padding: px2rem(4) px2rem(12);
    font-size: $otoklix-font-size-xxs;
    letter-spacing: 0.75px;
    font-weight: $font-weight-medium;
    line-height: px2rem(16);
  }

  &.pill {
    border-radius: $border-radius-pill;
  }
}
