.navbar {
  height: calc(100% - 60px);
  padding: 0;

  .navbar-menu {
    height: unset !important;
    overflow-y: auto;
    padding: 0 5px 5px 5px;
  
    & > li {
      list-style: none;
      display: block;
      width: 100%;
  
      & > a {
        padding: 10px;
        position: relative;
        display: block;
        padding: 12px 18px;
        color: #676765;
        font-size: 13px;
        font-weight: 400;
        transition: all 0.3s ease-out;
        text-decoration: none;
  
        & > i {
          display: inline-block;
          font-size: 16px;
          opacity: 0.9;
          color: #a6aed4;
        }
      }
  
      & > a.active {
        color: #000;
        font-weight: 600;
        background-color: #F5F5F4;
        border-radius: 8px;
      }
    }
  
    .vertical-menu-icon {
      width: 22px;
      height: 22px;
      margin-right: 6px;
    }
  }  

  .initial-avatar {
    background-color: #CFE2FF;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 22px;
    color: #676765;
  }
}
