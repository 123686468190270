.wrapper {
  position: relative;
  margin: 0px auto;
  background: $body-bg;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;

  .content-body {
    .card-title {
      font-weight: 600;
    }

    .card-subtitle {
      position: absolute;
      color: $primary;
      cursor: pointer;
      font-size: $otoklix-font-size-sm;
      top: 10px;
      right: px2rem(15);
    }

    .pleft-title {
      padding-left: px2rem(12);
    }
  }
}

.small-px {
  padding-left: px2rem(12) !important;
  padding-right: px2rem(12) !important;
}

@each $breakpoint, $value in $wrapper-breakpoints {
  .wrapper-#{$breakpoint} {
    max-width: $value;
  }
}

@include respond-below("sm") {
  .wrapper {
    min-width: 100%;
    max-width: 100%;
  }
}
