@import 'bootstrap';
@import 'otoklix-elements/otoklix';
@import 'materialdesignicons';
@import 'metrica';
@import 'variables';
@import 'popup';
@import 'override';
@import 'form';
@import 'responsive';
@import 'booking';
@import 'workshop';
@import 'packages';
@import 'stnk';
@import 'reviewratings';
@import 'inventory';
@import 'access_control_level';
@import 'subscriptions';
@import 'custom';
@import 'promo';
@import 'dashboard';
@import 'navbar';
@import 'orders';
@import 'stepper';
@import 'modal';
@import 'datepicker';
@import 'payments';
@import 'dropdown-search';
@import 'react-toastify/dist/ReactToastify.css';
@import 'react-checkbox-tree/lib/react-checkbox-tree.css';
@import "@szhsin/react-menu/dist/index.css";
@import '@szhsin/react-menu/dist/transitions/slide.css';
@import 'react-calendar/dist/Calendar.css';
@import 'react-spring-bottom-sheet/dist/style.css';
