.Stepper {
  span.active {
    color: #1A1919;
  }

  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.75px;
    color: #ACABA8;
  }

  hr.active {
    background-color: #002eb4;
  }
  
  hr {
    background-color: #ACABA8;
    width: 8%;
    margin: 0.75rem 0.5rem;
  }
}
